<template>
    <v-container fluid>
        <div class="text-h6">Ордера</div>
        <v-divider></v-divider>
        <v-tabs
            v-model="tab"
            align-tabs="left"
            color="primary"
            
        >
            <v-tab :value="1">Активные</v-tab>
            <v-tab :value="2">Успешные</v-tab>
            <v-tab :value="3">Отмененные</v-tab>
            <v-tab :value="4">Диспут</v-tab>
            <v-tab :value="5">Все</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item v-for="item, index in tabs" :key="index" :value="item.value">
                <v-card flat class="ma-2 white-back">
                    <OrderTable :status="item.status"/>
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
    </v-container>
</template>

<script>
import OrderTable from '@/components/orders/OrderTable.vue';

export default {
    name: 'OrdersView',
    components: {
        OrderTable
    },
    data: () => ({
        tab: null,
        tables_details: true,
        tables_groups: true,
        add_detail: false,
        add_group: false,
        tabs: [
            {status: "active", value: 1},
            {status: "archive", value: 2},
            {status: "canceled", value: 3},
            {status: "disputs", value: 4},
            {status: "all", value: 5},
        ]
    }),
    methods: {
        
    },
    watch: {
        
    }
}
</script>
