import axios from 'axios';
import router from '@/router'; 

// URL для обновления токена
// const refreshTokenUrl = 'http://127.0.0.1:8002/api/v1/user/token/refresh/';
const refreshTokenUrl = 'https://tradecity.io/api/v1/user/token/refresh/';

// Создаем экземпляр axios с поддержкой cookie (httpOnly)
const apiClient = axios.create({
  // baseURL: 'http://127.0.0.1:8002/api/v1/',
  baseURL: 'https://tradecity.io/api/v1/',
  withCredentials: true, // Включаем отправку cookie для httpOnly
});

let isRefreshing = false;
let failedQueue = [];

// Функция обработки очереди запросов
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

// Перехватчик для обработки 401 ошибок и обновления токена
apiClient.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then(token => {
        //   originalRequest.headers['Authorization'] = 'Bearer ' + token;
          console.log(token)
          return axios(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        axios.post(refreshTokenUrl, {}, { withCredentials: true })
          .then(({ data }) => {
            resolve(apiClient(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            router.push('/login');
            reject(err);
            return
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  }
);

function getCSRFToken() {
  const csrfCookieName = 'csrftoken';
  const cookies = document.cookie.split(';');
  for (let cookie of cookies) {
    cookie = cookie.trim();
    if (cookie.startsWith(`${csrfCookieName}=`)) {
      return cookie.substring(csrfCookieName.length + 1);
    }
  }
  return null;
}

// Добавляем перехватчик запросов
apiClient.interceptors.request.use(config => {
  const csrfToken = getCSRFToken();

  // Добавляем заголовок только для POST-запросов
  if ((config.method === 'post' || config.method === 'put' || config.method === 'delete') && csrfToken) {
    config.headers['X-CSRFToken'] = csrfToken;
  }

  return config;
}, error => {
  return Promise.reject(error);
});

export default apiClient;