<template>
    <v-container> 
        <div class="text-h6">Изменения баланса</div>
        <v-divider></v-divider>
        <v-card flat color="white">
          <BalanceChangeTable/>
        </v-card>
    </v-container> 
</template>

<script>
import BalanceChangeTable from '@/components/balancechange/BalanceChangeTable.vue';

  export default {
    components: { BalanceChangeTable, },
    data: () => ({
      tab: null,

    }),
    methods: {
        
    }
  }
  

</script>