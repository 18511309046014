<template>
  <v-container fluid class="d-flex flex-row pa-0" style="height: 100vh;">
    <!-- Чат -->
    <v-sheet class="flex-grow-1 d-flex flex-column">
      <!-- Заголовок чата -->
      <v-sheet class="pa-2" elevation="1" v-if="dispute_data">
        <v-row>
          <v-col cols="3">
            <h3 style="color: #ffffff">Диспут по Ордеру №{{ dispute_data.order.id }}</h3>
          </v-col>
          <v-col cols="3">
            <v-btn-toggle
              v-if="role === 'operator'"
              v-model="chat_with"
              color="deep-purple-accent-3"
              rounded="0"
              group
            >
              <v-btn value="merchant">
                Мерчант
              </v-btn>

              <v-btn value="trader">
                Трейдер
              </v-btn>

            </v-btn-toggle>
          </v-col>
          <v-col cols="6">
            <v-row v-if="role === 'operator'">
              <v-col cols="6">
                <v-btn color="green" @click="acceptOrder(dispute_data.order.id)">
                  Закрыть в пользу Мерчанта
                </v-btn>
              </v-col>
              
              <v-col cols="6">
                <v-btn color="red" @click="cancelOrder(dispute_data.order.id)">
                  Закрыть в пользу Трейдера
                </v-btn>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <!-- Контейнер с информацией -->
      <v-expansion-panels class="white-back" v-model="panel" multiple>

        <v-expansion-panel class="white-back  mt-0" v-if="role === 'operator'">
          <v-expansion-panel-title class="white-back" collapse-icon="mdi-minus" expand-icon="mdi-plus">
            Информация о мерчанте и трейдере
          </v-expansion-panel-title>
          <v-expansion-panel-text class="white-back">
            <UsersInfo v-if="dispute_data" :merchant="dispute_data.merchant" :trader="dispute_data.trader"/>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel class="white-back mt-0">
          <v-expansion-panel-title disable-icon-rotate class="white-back" collapse-icon="mdi-minus" expand-icon="mdi-plus">
            Информация об ордере
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <OrderInfo v-if="order_id" :order_id="order_id"/>
          </v-expansion-panel-text>
        </v-expansion-panel>

      </v-expansion-panels>
      <!-- Контейнер сообщений -->
      <v-sheet class="chat-messages" style="flex: 1; overflow-y: auto; padding: 10px;">
        
        <v-alert
          v-for="(message, index) in messages"
          :key="index"
          :class="(message.send_to === role) ? 'ma-1 float-start' : 'ma-1 float-end'"
          :border="(message.send_to === role) ? 'start' : 'end'"
          :border-color="(message.send_to === role) ? 'deep-purple accent-4' : 'green'"
          elevation="2"
          width="50%"
        >
          <template v-slot:title>
            <span class="message-title">{{ (message.send_to === role) ? getChatWith(): "Вы" }} {{ message.created_at }}</span>
          </template>
          <UseElementVisibility v-slot="{ isVisible }">
          {{ message.text }} {{ isVisible }}
          <!-- Отображение изображения -->
          <a v-if="message.image" :href="message.image" target="_blank">
          <v-img
            v-if="message.image"
            :src="message.image"
            class="mt-2"
          ></v-img></a>
          <!-- Отображение прикрепленного файла -->
          <a v-if="message.file" :href="message.file" target="_blank">{{ message.file }}</a>
          </UseElementVisibility>
        </v-alert>
      
      </v-sheet>

      <v-sheet v-if="files.length > 0">
        <v-row>
          <v-col cols="2" v-for="file, index in files" :key="index">
            <v-img v-if="file.image" :src="file.image_src" :width="300"></v-img>
            <div v-else>{{ file.file.name }}</div>
          </v-col>
        </v-row>
        <v-btn icon color="warning" class=float-end @click="files = []">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-sheet>

      <!-- Строка ввода -->
      <v-sheet elevation="3" class="d-flex align-center pa-2" v-if="dispute_data">
        <!-- Поле ввода сообщения -->
        <v-textarea
          v-model="messageText"
          label="Сообщение..."
          rows="1"
          auto-grow
          class="flex-grow-1"
          hide-details
          clearable
        ></v-textarea>

        <!-- Кнопка прикрепления файла -->
        <v-btn icon @click="selectFile">
          <v-icon>mdi-paperclip</v-icon>
        </v-btn>

        <!-- Кнопка отправки сообщения -->
        <v-btn icon color="primary" @click="sendMessage">
          <v-icon>mdi-send</v-icon>
        </v-btn>

        <!-- Поле для выбора файла (скрытое) -->
        <input type="file" ref="fileInput" @change="attachFile" hidden />
      </v-sheet>
    </v-sheet>

    <!-- Меню собеседников -->
    <v-navigation-drawer
      location="right"
      width="300"
      permanent
      class="white-back"
    >
      <v-list density="compact" nav>
        <v-list-item 
            v-for="dispute, index in disputes"
            :key="index"
            prepend-icon="mdi-chat-question-outline"
            :value="dispute.id"
            @click="selectDispute(dispute)"
          >
            <div>{{ 'Ордер №' + dispute.order.id }}</div>
            <!-- <div class="mini-info">Начат: {{ dispute.created_at }}</div> -->
            <div class="mini-info">Последние сообщение: {{ dispute.updated_at }}</div>
            <template v-slot:prepend v-if="dispute.unread > 0">
              <v-badge
                color="error"
                :content="dispute.unread"
                inline
              ></v-badge>
            </template>
          </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import OrderInfo from '@/components/disputes/OrderInfo.vue';
import UsersInfo from '@/components/disputes/UsersInfo.vue';
import apiClient from '@/plugins/axios';
import { UseElementVisibility } from '@vueuse/components';


export default {
  components: {
    OrderInfo,
    UsersInfo,
    UseElementVisibility
  },
  data() {
    return {
      timer: null,
      disputes: [],
      dispute_data: null,
      order_id: null,
      disputes_count: 0,
      chat_with: 'merchant',
      tabs: [
        {
          icon: 'mdi-store',
          text: 'Мерчант',
          tab: 'merchant',
        },
        {
          icon: 'mdi-chart-bar-stacked',
          text: 'Трейдер',
          tab: 'trader',
        },
      ],
      messages: [],
      files: [],
      message_text: null,

      messageText: '',
      attachedFile: null,
      panel: [1]
    };
  },
  methods: {
    acceptOrder(order_id) {
        this.saveData('payments/order/accept/', {order_id: order_id}).then(data => {
            console.log("Order data: ", data);
            this.getOrders()
        });
    },
    cancelOrder(order_id) {
        this.saveData('payments/order/cancel/', {order_id: order_id, cancel_status: 'operator_canceled'}).then(data => {
            console.log("Order data: ", data);
            this.getOrders()
        });
    },
    getChatWith() {
      if (this.role === 'operator') {
        if (this.chat_with === 'merchant') {return "Мерчант"}
        else {return "Трейдер"}
      }
      else {
        return "Оператор"
      }
    },
    sendMessage() {
      // if (this.messageText.trim() === '') return;
      if (this.files.length > 0) {
        this.files.forEach((file) => {
          const formData = new FormData();
          formData.append('order_id', this.order_id)
          formData.append(file.image? 'image': 'file', file.image? file.image: file.file)
          if (this.role === 'operator') {
            formData.append('send_to', this.chat_with)
          }
          this.sendData(formData)
        })
        this.files = []
      } 
      if (this.messageText.trim() !== '')  {
        const formData = new FormData();
        formData.append('order_id', this.order_id)
        formData.append('text', this.messageText)
        if (this.role === 'operator') {
            formData.append('send_to', this.chat_with)
        }
        this.sendData(formData)
        this.messageText = '';
      }
      this.getMessages(this.order_id)
       // Очистить поле после отправки
      this.scrollToBottom(); // Прокрутка вниз после отправки
    },
    attachFile(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const newMessage = {
            order_id: this.order_id,
            image: file.type.startsWith('image/') ? file : null,
            image_src: file.type.startsWith('image/') ? e.target.result : null,
            file: file.type.startsWith('image/') ? null : file,
          };
          console.log('file', newMessage)
          this.files.push(newMessage);
          this.scrollToBottom(); // Прокрутка вниз
        };
        reader.readAsDataURL(file);
      }
    },
    selectFile() {
      this.$refs.fileInput.click(); // Открыть выбор файла
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$el.querySelector('.chat-messages');
        console.log("Scroll bottom", chatContainer)
        // chatContainer.scrollTop = chatContainer.scrollHeight;
        setTimeout(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight; // Прокручиваем вниз
        }, 100);
      });
    },
    selectDispute(dispute) {
      this.dispute_data = dispute;
      this.order_id = dispute.order.id;
      this.getMessages(dispute.order.id)
      this.scrollToBottom()
    },
    getMessages(order_id) {
      this.getDataList('dispute/messages', {order_id: order_id, with_whom: this.chat_with}).then(data => {
          console.log("Messages table. Data: ", data);
          this.messages = data.results;

          this.sendMessageIds();
      });
    },
    getDisputes() {
      this.getDataList('dispute/disputes/').then(data => {
          console.log("Disputes table. Data: ", data);
          this.disputes = data.results;
          this.disputes_count = data.count;
          if (data.count > 0) {
            this.dispute_data = data.results[0]
            this.order_id = data.results[0].order.id
            this.getMessages(data.results[0].order.id)
          }
      });
    },
    sendMessageIds() {
      if (this.messages && this.messages.length) {
        const message_ids = this.messages
          .filter(message => message.status === true)
          .map(message => message.id);     
        console.log(message_ids);

        if (message_ids.length) {
          apiClient.put("/dispute/messages/read/", { message_ids: message_ids })
          .then(response => {
            console.log('Message IDs sent successfully:', response);
          })
          .catch(error => {
            if (error.response.data.message === "Сообщения не найдены.") {
              console.log("Сообщения не найдены.");
            } else {
              console.error('Error sending message IDs:', error);
            }
          });
        }
      }
    },
    sendData(form_data) {
      apiClient.post('dispute/message/send/', form_data, {
        headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => {
            console.log(response)
        })
        .catch(error => {
            console.error(error);
        });
    }
  },
  mounted() {
    this.scrollToBottom();
    this.getDisputes();

    this.timer = setInterval(() => {
      this.getDisputes();
      this.getMessages(this.order_id);
      this.sendMessageIds();
    }, this.$store.state.timerForGetMessage);
  },
  unmounted() {
    clearInterval(this.timer);
  },
  computed: {
      ...mapState({
        role: state => state.role
      }),
  },
  watch: {
    chat_with() {
      this.getMessages(this.dispute_data.order.id)
    }
  }
};
</script>

<style scoped>
.chat-messages {
  background-color: #f9f9f9;
}

.message-title {
  font-size: 0.8em;
  color: gray;
}

textarea.flex-grow-1 {
  width: 100%;
}
</style>
