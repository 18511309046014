import { createStore } from 'vuex'

export default createStore({
  state: {
    role: null,
    timerForGetMessage: 5000,
  },
  mutations: {
    setRole(state, role) {
      state.role = role;
    }
  },
  actions: {
    saveUserRole({ commit }, role) {
      commit('setRole', role);
    }
  },
  modules: {
  }
})
