<template>
  <v-col>
    <v-row class="row">
      <v-card
        v-for="(item, index) in statistics"
        :key="index"
        color="white" 
        class="card py-4 px-8 mb-3"
      >
        <div class="text-subtitle-2 mb-4">{{ item.title }}</div>
        <div class="text-h5 font-weight-medium mb-4">
          {{ item.number }}<span style="color: #5230b4;">
            {{ item.symbol }}{{ item.symbol === "шт" ? '.' : null }}
          </span>
        </div>
      </v-card>
    </v-row>
  </v-col>
</template>

<script>
export default {
  props: {
    state: {
      type: String,
      default: "view",
    },
  },
  data: () => ({
    statistics: [],
  }),
  mounted() {
    this.getViewStatistics();
  },
  methods: {
    getViewStatistics() {
      // Получаем липовые данные
      this.statistics = [
        {
          title: "Оборот",
          number: 0,
          symbol: "USDT",
        },
        {
          title: "Кэшбэк",
          number: 0,
          symbol: "USDT",
        },
        {
          title: "Количество сделок",
          number: 0,
          symbol: "шт",
        },
        {
          title: "Успешные сделки",
          number: 0,
          symbol: "шт",
        },
        {
          title: "Конверсия",
          number: 0,
          symbol: "%",
        },
        {
          title: "Средний чек",
          number: 0,
          symbol: "USDT",
        },
      ];
    },
  },
};
</script>

<style scoped>
.card {
  border: 2px solid #5230b4;
  flex-basis: calc(33.3333% - 12px);
  max-width: calc(33.3333% - 12px);
  margin-left: 6px;
  margin-right: 6px;
}

@media (max-width: 960px) {
  .card {
    flex-basis: calc(50% - 12px);
    max-width: calc(50% - 12px);
  }
}

@media (max-width: 600px) {
  .card {
    flex-basis: 100%;
    max-width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
</style>
