<template>
  <div class="text-h6 text-center mt-1" v-if="isMobile && ($route.path !== '/login' && $route.path !== '/merchant-test')">PAY WITH TRADERS</div>
  <v-divider v-if="isMobile"></v-divider>
  <component is="DesktopNav" v-if="!isMobile && ($route.path !== '/login' && $route.path !== '/merchant-test')"></component>
  <v-main>
      <router-view/>
  </v-main>
  <component is="MobileNav" v-if="isMobile && ($route.path !== '/login' && $route.path !== '/merchant-test')"></component>
  <DeleteDialog />
</template>

<script>
import { defineComponent } from 'vue';

import DesktopNav from '@/components/nav/DesktopNav.vue';
import MobileNav from '@/components/nav/MobileNav.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    DesktopNav,
    MobileNav,
    DeleteDialog,
  },

  data() {
    return {
      isMobile: false,
    };
  },
  mounted() {
    this.checkViewport();
    window.addEventListener('resize', this.checkViewport);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkViewport);
  },
  methods: {
    checkViewport() {
      this.isMobile = window.innerWidth <= 600;
    },
  },
});
</script>

