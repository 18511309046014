<template>
    <v-container fluid>
        <div class="text-h6">Реквизиты</div>
        <v-divider></v-divider>
        <v-tabs
            v-model="tab"
            align-tabs="left"
            color="primary"
            
        >
            <v-tab :value="1">Реквизиты</v-tab>
            <v-tab :value="2">Группы</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item key="1" :value="1">
                <v-card
                    flat
                    class="ma-2 white-back d-flex align-end flex-column"
                >   
                    <v-btn color="primary" class="mb-4" @click="addDetailOpen" v-if="tables_details">Добавить</v-btn>
                    <DetailsTable 
                        @open-dialog="open_delete_dialog" 
                        @swap-to-update-detail-table="swap_to_update_detail_table" 
                        ref="detailsTableComponent" 
                        v-if="tables_details"
                        style="width: 100%;"
                    />
                </v-card>
            </v-tabs-window-item>
            <v-tabs-window-item key="2" :value="2">
                <v-card
                    flat
                    class="ma-2 white-back d-flex align-end flex-column"
                >
                    <v-btn color="primary" class="mb-4" @click="addGroupOpen" v-if="tables_groups">Добавить</v-btn>
                    <GroupsTable 
                        @open-dialog="open_delete_dialog" 
                        @swap-to-update-group-table="swap_to_update_group_table" 
                        ref="groupsTableComponent" 
                        v-if="tables_groups"
                        style="width: 100%;"
                    />
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
        <DetailsAdd v-if="add_detail" @clear-update-id="clear_update_id" :update_id="update_detail_table_id" @close-add="openDefaultView"/>
        <GroupAdd v-if="add_group" @clear-update-id="clear_update_id" :update_id="update_group_table_id" @close-add="openDefaultView"/>
    </v-container>
    <DeleteDialog 
        :what_delete="what_delete" 
        :open="delete_dialog" 
        :id="dialog_id" 
        :warning="dialog_warning" 
        @close-dialog="close_dialog" 
    />
</template>

<script>
import DetailsTable from '@/components/details/DetailsTable.vue';
import DetailsAdd from '@/components/details/DetailsAdd.vue';
import GroupsTable from '@/components/details/GroupsTable.vue';
import GroupAdd from '@/components/details/GroupAdd.vue';
import DeleteDialog from '@/components/dialogs/DeleteDialog.vue';

export default {
    name: 'DetailsView',
    components: {
        DetailsTable,
        DetailsAdd,
        GroupsTable,
        GroupAdd,
        DeleteDialog,
    },
    data: () => ({
        tab: null,
        tables_details: true,
        tables_groups: true,
        add_detail: false,
        add_group: false,
        update_detail_table_id: null,
        update_group_table_id: null,
        delete_dialog: false,
        dialog_id: null,
        dialog_warning: null,
        what_delete: null,
    }),
    mounted() {
        this.update_detail_table_id = null;
        this.update_group_table_id = null;
    },
    methods: {
        clear_update_id() {
            this.update_group_table_id = null;
            this.update_detail_table_id = null;
        },
        close_dialog() {
            this.delete_dialog = false;
            this.dialog_id = null;
            this.dialog_warning = null;

            this.refreshTables();
        },
        open_delete_dialog(value) {
            if (value.what_delete === "details") {
                this.dialog_warning = "Все реквезиты входящие в группу тоже удалятся";
            }

            this.delete_dialog = value.open;
            this.what_delete = value.what_delete;
            this.dialog_id = value.id;
        },
        swap_to_update_group_table(value) {
            this.update_group_table_id = value;

            this.tables_groups = false;
            this.add_group = true;
        },
        swap_to_update_detail_table(value) {
            this.update_detail_table_id = value;

            this.tables_details = false;
            this.add_detail = true;
        },
        addDetailOpen() {
            this.tables_details = false;
            this.add_detail = true;
        },
        addGroupOpen() {
            this.tables_groups = false;
            this.add_group = true;
        },
        openDefaultView() {
            this.tables_details = true
            this.tables_groups = true
            this.add_detail = false
            this.add_group = false

            this.update_detail_table_id = null;

            this.refreshTables();
        },
        async refreshTables() {
            await this.$nextTick();

            if (this.tab === 1 && this.$refs.detailsTableComponent) {
                this.$refs.detailsTableComponent.getDetails();
            } else if (this.tab === 2 && this.$refs.groupsTableComponent) {
                this.$refs.groupsTableComponent.getDetailsGroups();
            } else {
                console.error('Дочерний компонент не найден');
            }
        },
    },
    watch: {
        tab() {
            this.openDefaultView()
        },
    }
}
</script>
