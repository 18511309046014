<template>
  <NoData v-if="desserts.length === 0"/>
  <v-data-table-server
        v-else
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="desserts"
        :items-length="totalItems"
        :loading="loading"
        @update:options="getTransactions"
        :page="page"
        class="white-back"
        :items-per-page-options="[5, 10, 20, 50, 100]"
  >
    <template v-slot:item.id="{ item }">
      <div>
        {{ item.id }}
      </div>
    </template>
    <template v-slot:item.currency="{ item }">
      <div>
        {{ item.currency.name }}
      </div>
    </template>
    <template v-slot:item.date="{ item }">
      <div>
        {{ item.updated_at }}
      </div>
    </template>
    <template v-slot:item.available="{ item }">
      <div>{{ item.balance }}</div><div v-html="changeBalanceFormat(item.balance_change)"></div>
    </template>
    <template v-slot:item.freeze="{ item }">
      <div>{{ item.freeze_balance }}</div><div v-html="changeBalanceFormat(item.freeze_balance_change)"></div>
    </template>
  </v-data-table-server>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
  components: { NoData, },
  data: () => ({
    search: '',
    itemsPerPage: 10,
    page: 1,
    headers: [
          {
            align: 'start',
            key: 'id',
            sortable: false,
            title: 'ID',
          },
          { key: 'currency', title: 'Валюта' },
          { key: 'date', title: 'Дата' },
          { key: 'available', title: 'Доступно' },
          { key: 'freeze', title: 'Заморожено' },
          { key: 'basis_name', title: 'Основание' },
    ],
    desserts: [],
    totalItems: 0,
    loading: true,
    totalItems: 0,
  }),
  methods: {
    getTransactions() {
      this.getDataList('/payments/trs', {page_size: this.itemsPerPage, page: this.page}).then(data => {
        console.log(data);
        this.desserts = data.results;
        this.loading = false;
        this.totalItems = data.count;
      });
    },
    changeBalanceFormat(value) {
        if (value === 0) return null
        else if (value > 0) return '<span style="color:green">(+' + value + ')</span>'
        else return '<span style="color:red">(' + value + ')</span>'
    }
  },
  mounted() {
    this.getTransactions()
  }
};
</script>
