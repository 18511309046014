import '@mdi/font/css/materialdesignicons.css'
import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import apiClient from '@/plugins/axios'


loadFonts()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(vuetify, {
    iconfont: 'mdi'
  })
  .mixin({
    methods: {
      cutString(message, cut) {
        const result = message.substr(0,cut);
        return result
      },
      logOut() {
        apiClient.get('user/logout/').then(response => {
          console.log("re", response.data)
          this.$router.push("/login")
        })
        .catch(error => {
            console.error(error);
        });
      },
      formatInput(fieldName) {
        return (event) => {
            this.values[fieldName] = event.target.value.replace(/\D/g, '');
        };
      },
      formatNumber(value, groupSize) {
        const regex = new RegExp(`\\B(?=(\\d{${groupSize}})+(?!\\d))`, 'g');
        return value.replace(regex, ' ');
      },
      async getDataList(path, params=null) {
        try {
          const response = await apiClient.get(path, {params: params})
          return response.data
        } catch (error) {
          console.error(error)
          return []
        }
      },

      async saveData(path, data) {
        try {
          const response = await apiClient.post(path, data)
          return response.data
        } catch (error) {
          console.error(error)
          return null
        }
      },
      async updateData(path, data) {
        try {
          const response = await apiClient.put(path, data)
          return response.data
        } catch (error) {
          console.error(error)
          return null
        }
      },
      async deleteData(path) {
        try {
          const response = await apiClient.delete(path)
          return response.data
        } catch (error) {
          console.error(error)
          return null
        }
      },
    },
  })
  .mount('#app')


