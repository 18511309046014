<template>
  <v-dialog max-width="320" v-model="localOpen">
    <v-card color="white" class="card py-6 ma-auto">
      <v-col>
        <v-row class="align-center justify-center flex-column">
          <v-icon color="red" class="icon">mdi-delete</v-icon>
          <div class="text-center text-h6" :class="!warning ? 'mb-4' : null">Подтвердите действие</div>
          <div v-if="warning" class="text-center warning mb-4 mt-2">{{ warning }}</div>
          <v-col>
            <v-row class="justify-center">
              <v-btn @click="agree" class="mx-2 text-none" elevation="0" variant="outlined" color="red" width="100">Да</v-btn>
              <v-btn @click="cancel" class="mx-2 text-none" elevation="0" variant="outlined" color="green" width="100">Отмена</v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    what_delete: {
      type: String,
      default: null,
    },
    open: {
      type: Boolean,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    },
    warning: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    cancel() {
      this.$emit("close-dialog");
    },
    agree() {
      if (this.what_delete === "details") {
        this.deleteData(`trader/detail/${this.id}/delete/`).then(data => {
          this.$emit("close-dialog", false);
        });
      }

      if (this.what_delete === "group") {
        this.deleteData(`trader/details/group/${this.id}/delete/`).then(data => {
          this.$emit("close-dialog", false);
        });
      }
    },
  },
  computed: {
    localOpen: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("close-dialog", value);
      },
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  width: 320px;
}

.icon {
  font-size: 56px;
}

.warning {
  max-width: 200px;
}
</style>
