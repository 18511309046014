<template>
    <NoData v-if="desserts.length === 0"/>
    <v-data-table-server
        v-else
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="desserts"
        :items-length="totalItems"
        :loading="loading"
        @update:options="getOrders"
        :page="page"
        class="white-back"
        :items-per-page-options="[5, 10, 20, 50, 100]"
    >
        <!-- Основной слот для строки -->
        <template v-slot:item="{ item }">
            <tr class="clicable" @click="open_order_dialog(item)">
                <!-- Столбец id -->
                <td>
                    <div style="white-space: pre-line;">
                        <span>#{{ item.id }}</span><br>
                        <span class="text-subtitle-2 mini-date">{{ item.updated_at }}</span>
                    </div>
                </td>

                <!-- Столбец rate -->
                <td>
                    <div style="white-space: pre-line;">
                        <span>{{ item.rate.rate }} ₽</span><br>
                    </div>
                </td>

                <!-- Столбец sum -->
                <td>
                    <div style="white-space: pre-line;">
                        <span>{{ item.in_value }} {{ item.in_currency.symbol }}</span><br>
                        <v-chip color="green">{{ item.out_value }} {{ item.out_currency.symbol }}</v-chip>
                    </div>
                </td>

                <!-- Столбец details -->
                <td>
                    <div>
                        {{ item.details.name }}
                        {{ item.details.tr_type.name }}
                        {{ item.details.bank.name }}
                        {{ item.details.card_number }}
                        {{ item.details.validity_period }}
                        {{ item.details.phone_number }}
                        {{ item.details.owner }}
                    </div>
                </td>

                <!-- Столбец payment -->
                <td>
                    <div style="white-space: pre-line;">
                        <span>{{ item.tr_types.translated_name }}</span><br>
                    </div>
                </td>

                <!-- Столбец status -->
                <td>
                    <div style="white-space: pre-line;">
                        <span>{{ item.order_status }} {{ item.processed }} {{ item.cancel_status }}</span><br>
                    </div>
                </td>

                <!-- Столбец actions -->
                <td v-if="status === 'active'">
                    <v-btn icon size="small" @click="acceptOrder(item.id)"><v-icon color="green">mdi-check-circle</v-icon></v-btn>
                    <!-- <v-btn icon size="small"><v-icon color="red">mdi-cancel</v-icon></v-btn> -->
                </td>
            </tr>
        </template>
    </v-data-table-server>
    <OrderDialog
        :details="order_dialog_details"
        :order_data="order_dialog_order_data"
        :open="order_dialog"
        @open-receipts-dialog="open_receipts_dialog"
        @close-dialog="close_order_dialog"
    />
    <ReceiptsDialog
        :open="receipts_dialog"
        :receipts="receipts_dialog_data"
        @close-dialog="close_receipts_dialog"
    />
</template>


<script>
import NoData from '@/components/alerts/NoData.vue';
import OrderDialog from '@/components/dialogs/OrderDialog.vue';
import ReceiptsDialog from '@/components/dialogs/ReceiptsDialog.vue';

import ExampleReceiptsImage from "@/assets/receipts-image/example-receipts.jpg";

export default {
    components: {
        NoData,
        OrderDialog,
        ReceiptsDialog,
    },
    props: {
        status: {
            type: String,
            default: "all",
        },
    },
    data: () => ({
        receipts_dialog: false,
        receipts_dialog_data: null,
        order_dialog: false,
        order_dialog_details: null,
        order_dialog_order_data: null,
        itemsPerPage: 10,
        page: 1,
        headers: [
            { key: 'id', title: 'ID', align: 'start', sortable: false },
            { key: 'rate', title: 'Курс' },
            { key: 'sum', title: 'Сумма' },
            { key: 'details', title: 'Реквизиты' },
            { key: 'payment', title: 'Оплата' },
            { key: 'status', title: '' },
        ],
        desserts: [],
        totalItems: 0,
        loading: true,
        totalItems: 0,
    }),
    methods: {
        open_receipts_dialog(value) {
            this.receipts_dialog = true;
            this.receipts_dialog_data = value;

            console.log("receipts: ", value);
        },
        close_receipts_dialog() {
            this.receipts_dialog = false;
        },
        open_order_dialog(item) {
            this.order_dialog_details = item.details;
            this.order_dialog_order_data = {
                order_status: item.order_status,
                in_value: item.in_value,
                out_value: item.out_value,
                in_currency_symbol: item.in_currency.symbol,
                out_currency_symbol: item.out_currency.symbol,
                updated_at: item.updated_at,
                trader_reward: null,
                // receipts: null,
                receipts: {
                    image: ExampleReceiptsImage,
                },
            };
            this.order_dialog = true;

            console.log(item, this.order_dialog_details); 
        },
        close_order_dialog() {
            this.order_dialog = false;
        },
        getOrders() {
            this.getDataList('payments/orders', {status: this.status, page_size: this.itemsPerPage, page: this.page}).then(data => {
                console.log("Orders data: ", data);
                this.desserts = data.results;
                this.loading = false;
                this.totalItems = data.count;
            });
        },
        acceptOrder(order_id) {
            this.saveData('payments/order/accept/', {order_id: order_id}).then(data => {
                console.log("Order data: ", data);
                this.getOrders()
            });
        }
    },
    mounted() {
        this.getOrders();
        if (this.status === 'active') {
            this.headers.push({ key: 'actions', title: 'Действия' })
        } 
    }
}
  
</script>

<style scoped>
.mini-date {
    color:dimgray;
    font-size: 0.25em;
    font-weight: 400;
    white-space: nowrap;
}
.clicable {
    cursor: pointer;
}
</style>