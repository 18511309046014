// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { ru } from 'vuetify/locale'

// Vuetify
import { createVuetify } from 'vuetify'

const vuetify = createVuetify({
  locale: {
    locale: 'ru',
    fallback: 'ru',
    messages: { ru },
  },
  theme: {
    defaultTheme: 'customTheme',
    themes: {
      customTheme: {
        dark: false,
        colors: {
          primary: '#5a62e1',
          secondary: '#fde5e5',
          info: '#5230b4',
          success: '#d158fe',
          background: '#ffffff',
          error: '#ee78a5',
          warning: '#ebabjd',
          surface: '#a9b6f9',
          onSurface: '#FFFFFF'
          // surface: '#01440f'
        },
      },
    },
  },
})

export default vuetify

