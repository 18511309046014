import apiClient from '@/plugins/axios'

export const checkAuth = async () => {
    try {
      const response = await apiClient.get('user/check-auth/'); 
      const userRole = response.data.role;
      return userRole; 
    } catch (error) {
      return null;
    }
};