<template>
  <v-container fluid>
    <div class="text-h6">Автоматизация</div>
    <v-divider></v-divider>
    <v-tabs v-model="tab" align-tabs="left" color="primary">
      <v-tab :value="1">Активные</v-tab>
      <v-tab :value="2">Успешные</v-tab>
      <v-tab :value="3">Ошибка</v-tab>
      <v-tab :value="4">Ошибка шаблона</v-tab>
      <v-tab :value="5">Технические</v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-tabs-window-item
        v-for="(item, index) in tabs"
        :key="index"
        :value="item.value"
      >
        <v-card flat class="ma-2 white-back">
          <AutomationTable :state="item.state" />
        </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-container>
</template>

<script>
import AutomationTable from '@/components/automation/AutomationTable.vue';

export default {
  components: { AutomationTable },
  data: () => ({
    tab: null,
    tabs: [
      { state: "active", value: 1 },
      { state: "successful", value: 2 },
      { state: "error", value: 3 },
      { state: "template_error", value: 4 },
      { state: "technical", value: 5 },
    ],
  }),
};
</script>
