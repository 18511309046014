<template>
    <v-container fluid> 
        <div class="text-h6">Балансы</div>
        <v-divider></v-divider>
        <v-alert v-if="role === 'trader'"
            color="info"
            icon="$info"
            title="Вознаграждение"
            :text="reward + ' %'"
            class="ma-2"
        ></v-alert>
        <v-row no-gutters>
            <v-col
                cols="6"
            >
                <v-card class="info-card ">
                    <v-card-title class="custom-surface">Рабочий Баланс</v-card-title>
                    <v-card-text>
                        <v-row class="mb-2">
                            <v-col cols="6">
                                <div style="white-space: pre-line;">
                                    <span class="sum">{{ work_balance }}</span> <span class="currency-name">USD₮</span><br>
                                    <span class="text-subtitle-2 balance-type">Доступно</span>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div style="white-space: pre-line;">
                                    <span class="sum balane-freeze">{{ work_balance_freeze }}</span> <span class="currency-name">USD₮</span><br>
                                    <span class="text-subtitle-2 balance-type">Заморожено</span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-dialog
                          transition="dialog-top-transition"
                          width="400"
                          v-model="newPayDialog"
                        >
                          <v-card color="white">
                            <v-toolbar title="Предупреждение" color="primary"></v-toolbar>

                            <v-card-text>Вы уверены, что хотите сменить адрес? <br /> Все не поступившие платежи будут отменены!</v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                class="ms-auto"
                                color="warning"
                                outline
                                @click="newPayDialog = false"
                                >Отмена</v-btn>
                              <v-btn
                                class="ml-0"
                                color="primary"
                                outline
                              >Принять</v-btn>
                            </v-card-actions>
                          </v-card>           
                        </v-dialog>
                        <v-dialog
                          transition="dialog-top-transition"
                          width="400"
                          v-model="with_dialog"
                        >
                          <v-card>
                            <v-toolbar title="Вывод с баланса"></v-toolbar>

                            <v-card-text v-if="!success">
                              <div>Сеть: TRC-20</div>
                              <v-text-field
                                v-model="address"
                                density="compact"
                                label="Адрес*"
                                variant="outlined"
                                :rules="withdrawalAddressRule"
                                required
                              ></v-text-field>
                              <v-text-field
                                v-model="amount"
                                density="compact"
                                label="Сумма*"
                                variant="outlined"
                                :rules="balanceRule"
                                required
                              ></v-text-field>
                                <div>Комиссия: {{ commission }} {{ symbol }}</div>
                                <div>Сумма выплаты: {{ sum }} {{ symbol }}</div>
                            </v-card-text>
                            <v-card-text v-else>
                              <div>Заявка на вывод успешно создана!</div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                class="ms-auto"
                                :text="(!success)?'Отмена':'Ок'"
                                color="warning"
                                outline
                                @click="with_dialog = false"
                              ></v-btn>
                              <v-btn
                                v-if="!success"
                                class="ml-0"
                                text="Создать"
                                color="primary"
                                outline
                                @click="createTr"
                              ></v-btn>
                            </v-card-actions>
                          </v-card>  
                        </v-dialog>
                      <v-btn rounded="xs" size="large" block class="mt-2" color="primary" @click="openWithdrawaldialog('work')">Вывести</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="6"
            >
                <v-card class="info-card" v-if="role === 'trader'">
                    <v-card-title class="custom-surface">Страховой депозит</v-card-title>
                    <v-card-text>
                        <v-row class="mb-2">
                            <v-col cols="6">
                                <div style="white-space: pre-line;">
                                    <span class="sum">{{ deposit_balance }}</span> <span class="currency-name">USD₮</span><br>
                                    <span class="text-subtitle-2 balance-type">Доступно</span>
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div style="white-space: pre-line;">
                                    <span class="sum balane-freeze">{{ deposit_balance_freeze }}</span> <span class="currency-name">USD₮</span><br>
                                    <span class="text-subtitle-2 balance-type">Заморожено</span>
                                </div>
                            </v-col>
                        </v-row>
                        <v-btn rounded="xs" size="large" block class="mt-2" color="primary" @click="openWithdrawaldialog('dep')">Вывести</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col
                cols="6"
            >
                <v-card class="info-card">
                    <v-card-title  class="custom-surface">Кошелек для пополнения баланса</v-card-title>
                    <v-card-text>
                        <div style="white-space: pre-line;">
                            <span class="wallet-titles">Сеть:</span> <span class="wallet-data">{{ work_network }}</span>
                        </div>
                        <div style="white-space: pre-line;">
                            <span class="wallet-titles">Адрес:</span> <span class="wallet-data">{{ work_address }}</span> 
                            <v-btn @click="copyToClipboard(work_address, 'work')" icon size="xsmall" class="ml-1" v-if="work_address"><v-icon>mdi-content-copy</v-icon></v-btn>
                        </div>
                        <div style="white-space: pre-line;" class="text-subtitle-2 balance-type">
                            {{ work_address_creared_at }}
                        </div>
                        <v-btn @click="openNewPayDialog()" rounded="xs" size="large" block  prepend-icon="mdi-refresh" class="mt-2" color="primary">Создать новый</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="6"
            >
                <v-card class="info-card" v-if="role === 'trader'">
                    <v-card-title class="custom-surface">Кошелек для пополнения депозита</v-card-title>
                    <v-card-text>
                        <div style="white-space: pre-line;">
                            <span class="wallet-titles">Сеть:</span> <span class="wallet-data">{{ deposit_network }}</span>
                        </div>
                        <div style="white-space: pre-line;">
                            <span class="wallet-titles">Адрес:</span> <span class="wallet-data">{{  deposit_address }}</span> 
                            <v-btn @click="copyToClipboard(deposit_address, 'deposit')" icon size="xsmall" class="ml-1" v-if="deposit_address"><v-icon>mdi-content-copy</v-icon></v-btn>
                        </div>
                        <div style="white-space: pre-line;"  class="text-subtitle-2 balance-type">
                            {{ deposit_address_creared_at }}
                        </div>
                        <v-btn rounded="xs" size="large" block  prepend-icon="mdi-refresh" class="mt-2" color="primary">Создать новый</v-btn>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <successfull-copy-dialog :error="copyError" :isCopied="isCopied" :open="copyDialog" @close-dialog="closeCopyDialog()" />
    </v-container>
</template>

<script>
import apiClient from '@/plugins/axios'
// import WithdrawalDialog from '@/components/withdrawal/WithdrawalDialog.vue';
import SuccessfullCopyDialog from '@/components/dialogs/SuccessfullCopyDialog.vue';
import { mapState } from 'vuex';

export default {
    components: {
        SuccessfullCopyDialog,
    },
    data: () => ({
        newPayDialog: false,
        copyDialog: false,
        copyError: false,
        isCopied: null,
        reward: 0,
        work_balance: 0,
        work_balance_freeze: 0,
        deposit_balance: 0,
        deposit_balance_freeze: 0,
        work_network: null,
        work_address: null,
        work_address_creared_at: null,
        deposit_network: null,
        deposit_address: null,
        deposit_address_creared_at: null,
        with_dialog: true,
        balance_id: 'work',
        symbol: null,
        success: false,
        withdrawalAddressRule: [
            v => !!v || "Адрес обязателен",
        ],
        amount: null,
        address: null

    }),
    methods: {
        openNewPayDialog() {
          this.newPayDialog = true;
        },
        closeCopyDialog() {
          this.copyDialog = false;
        },
        copyToClipboard(address, type) {
          this.copyError = false;

          if (type === "deposit") {
            this.isCopied = this.deposit_address;
          }

          if (type === "work") {
            this.isCopied = this.work_address;
          }

          this.copyDialog = true;

          navigator.clipboard.writeText(address).then(() => {
            console.log('Адрес успешно скопирован в буфер обмена!');
          }).catch(err => {
            this.copyError = true;
            console.error('Ошибка при копировании в буфер обмена: ', err);
          });
        },
        getBalances() {
            this.with_dialog = false
            this.balance_id = null
            apiClient.get('payments/balance/')
            .then(response => {
                this.reward = response.data.reward
                this.work_balance = response.data.work.balance
                this.work_balance_freeze = response.data.work.freeze_balance
                this.deposit_balance = (response.data.dep)?response.data.dep.balance: null
                this.deposit_balance_freeze = (response.data.dep)?response.data.dep.freeze_balance: null
                this.work_network = response.data.work.network
                this.work_address = response.data.work.address
                this.work_address_creared_at = response.data.work.updated_at
                this.deposit_network = (response.data.dep)?response.data.dep.network: null
                this.deposit_address = (response.data.dep)?response.data.dep.address: null
                this.deposit_address_creared_at =response.data.dep.updated_at
                this.symbol = response.data.work.currency_symbol
            })
            .catch(error => {
                console.error(error);
            });
        },
        openWithdrawaldialog(balance_id) {
            this.balance_id = balance_id;
            this.with_dialog = true;
        },
        createTr() {
            this.saveData('payments/withdrawal/create/', {balance_id: this.balance_id, amount: this.amount, address: this.address }).then(data => {
                console.log(data);
                this.success = data.result
            });
        },
    },
    created() {
        this.getBalances()
    },
    computed: {
      ...mapState({
        role: state => state.role
      }),
      balanceRule() {
        return [
          v => !!v || "Сумма обязательна",
          v => v <= (this.balance_id === 'dep' ? this.deposit_balance - 3 : this.work_balance - 3) || "Сумма должна быть меньше доступного баланса на 3 USDT"
      ];
    }
    },
    watch: {
        // balance_id() {
        //     if (this.balance_id) {
        //         this.with_dialog = true
        //     }
        // }
    },
}
</script>

<style scoped>
.info-card {
    margin: 8px;
}
.sum {
    font-size: 2em;
    font-weight: 500;
}
.currency-name {
    font-size: 1.5em;
    font-weight: 400;
    color: #5230b4;
}
.balance-type {
    color:dimgray;
}
.balane-freeze{
    color: #ee78a5;
}
.wallet-titles {
    font-size: 1.5em;
    font-weight: 600;
}
.wallet-data {
    font-size: 1.2em;
    font-weight: 400;
}
@media (max-width: 600px) {
    .info-card {
        margin: 1px;
        margin-bottom: 8px;
    }
}
</style>