<template>
    <div class="text-center pa-4">
        <v-dialog
            v-model="dialog_open"
            width="auto"
        >
            <v-card
                max-width="400"
                prepend-icon="mdi-cash-multiple"
                title="Принять внутренний перевод"
                class="white-back"
            > 
                <v-card-text v-if="!amount">
                    <v-text-field
                        v-model="code"
                        density="compact"
                        label="Код перевода*"
                        variant="outlined"
                        required
                        :rules="codeRules"
                    ></v-text-field>
                </v-card-text>
                <v-card-text v-else>
                    <div>Вы получили перевод {{ amount }} {{ symbol }}</div>
                </v-card-text>
                <template v-slot:actions class="">
                    <v-btn
                        class="ms-auto"
                        :text="(!amount)?'Отмена':'Ок'"
                        color="warning"
                        outline
                        @click="dialog_open = false"
                    ></v-btn>
                    <v-btn
                        v-if="!amount"
                        class="ml-0"
                        text="Принять"
                        color="primary"
                        outline
                        @click="findTr"
                        :disabled="disableAccept"
                    ></v-btn>
                </template>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
// import apiClient from '@/plugins/axios';
export default {
    props: {
        dialog: {
            type: Boolean,
            default: false,
        },
        symbol: {
            type: String,
            default: null
        }
    },
    data: () => ({
        dialog_open: false,
        code: null,
        amount: null,
        codeRules: [
            v => !!v || "Код перевода обязателен",
        ],
    }),
    methods: {
        findTr() {
            this.saveData('payments/internal/pay/', {code: this.code }).then(data => {
                console.log(data);
                this.amount = data.amount
            });
        }
    },
    computed: {
        disableAccept() {
            return !this.amount || !this.code;
        }
    },
    watch: {
        dialog() {
            if (this.dialog) {
                this.dialog_open = true
            }
        },
        dialog_open() {
            if (!this.dialog_open) {
                this.$emit('close-dialog');
            }
        }
    }
}
</script>