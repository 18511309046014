<template>
    <NoData v-if="desserts.length === 0"/>
    <v-data-table
        v-else
        :headers="headers"
        :items="desserts"
        class="white-back"
    >
        <template v-slot:item.status="{ item }">
            <v-switch
                color="warning"
                hide-details
                v-model="item.value"
                @change=""
            ></v-switch>
        </template>
        <template v-slot:item.id="{ item }">
            <div style="white-space: pre-line;">
                <span>#{{ item.id }}</span><br>
            </div>
        </template>
        <template v-slot:item.phones_ids="{ item }">
            <div v-for="phone_id in item.phone_ids" :key="phone_id.id" style="white-space: pre-line;">{{ phone_id.phone_id }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-col>
                <v-row style="flex-wrap: nowrap;">
                    <v-btn color="transparent" elevation="0" icon size="small"><v-icon color="green" size="26">mdi-auto-mode</v-icon></v-btn>
                    <v-btn @click="swap_to_update_group_table(item.id)" color="transparent" elevation="0" icon size="small">
                        <v-icon color="blue" size="26">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="open_dialog(item.id)" color="transparent" elevation="0" icon size="small">
                        <v-icon color="red" size="26">mdi-delete</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </template>
        <template v-slot:header.actions>
            <div class="text-center">Действия</div>
        </template>
        
    </v-data-table>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
    components: {
        NoData,
    },
    data: () => ({
        headers: [
            { key: 'id', title: 'ID' },
            { key: 'name', title: 'Название' },
            { key: 'api_key', title: 'API ключ' },
            { key: 'phones_ids', title: 'ID для приложений'},
            { key: 'translated_automation', title: 'Способ автоматизации' },
            { key: 'actions', title: 'Действия' },
        ],
        desserts: []
    }),
    methods: {
        swap_to_update_group_table(id) {
            // Передать group id в DetailsVIew
            this.$emit("swap-to-update-group-table", id);
        },
        getDetailsGroups() {
            this.getDataList('trader/details/groups/').then(data => {
                console.log(data);
                this.desserts = data;
            });
        },
        open_dialog(id) {
            this.$emit("open-dialog", {
                open: true,
                what_delete: "group",
                id: id,
            });
        },
    },
    mounted() {
        this.getDetailsGroups()
    }
}
  
</script>