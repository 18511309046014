<template>
  <v-dialog v-model="localOpen" max-width="320">
    <v-card color="white" class="card py-4 px-6 ma-auto">
      <v-col>
        <v-row class="align-center justify-space-between">
          <div>Квитанции</div>
          <v-icon size="18" @click="close_dialog">mdi-close</v-icon>
        </v-row>
        <NoData class="nodata-small mt-8" v-if="!receipts" />
        <v-img class="mt-8" v-else :src="receipts.image" />
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
  components: { NoData },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    receipts: {
      type: Object,
      default: null,
    },
  },
  computed: {
    localOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit("close-dialog");
      },
    },
  },
  methods: {
    close_dialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 4px !important;
  width: 320px;
}
</style>
