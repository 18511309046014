<template>
  <v-container>
    <div class="text-h6">Статистика</div>
    <v-divider></v-divider>
    <v-tabs v-model="tab" align-tabs="left" color="primary">
      <v-tab :value="1">Обзор</v-tab>
      <v-tab :value="2">Обзор по дням</v-tab>
      <v-tab :value="3">Реквизиты</v-tab>
      <v-tab :value="4">Трейдеры рефералы</v-tab>
    </v-tabs>
    <v-tabs-window v-model="tab">
      <v-tabs-window-item
        v-for="(item, index) in tabs"
        :key="index"
        :value="item.value"
      >
        <v-card flat class="ma-2 white-back">

          <ViewTable v-if="tab === 1" />
          <ViewByDayTable v-if="tab === 2" />
          <DetailsStatisticsTable v-if="tab === 3" />
          <TradersTable v-if="tab === 4" />

        </v-card>
      </v-tabs-window-item>
    </v-tabs-window>
  </v-container>
</template>

<script>
import DetailsStatisticsTable from '@/components/statistics/DetailsStatisticsTable.vue';
import TradersTable from '@/components/statistics/TradersTable.vue';
import ViewByDayTable from '@/components/statistics/ViewByDayTable.vue';
import ViewTable from '@/components/statistics/ViewTable.vue';

export default {
  components: { 
    ViewTable,
    TradersTable,
    ViewByDayTable,
    DetailsStatisticsTable,
  },
  data: () => ({
    tab: null,
    tabs: [
      { state: "view", value: 1 },
      { state: "view_by_day", value: 2 },
      { state: "details", value: 3 },
      { state: "traders", value: 4 },
    ],
  }),
};
</script>
