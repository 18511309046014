<template>
  <NoData v-if="desserts.length === 0"/>
  <v-data-table-server
        v-else
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="desserts"
        :items-length="totalItems"
        :loading="loading"
        @update:options="getTransactions"
        :page="page"
        class="white-back"
        :items-per-page-options="[5, 10, 20, 50, 100]"
  >
    <template v-slot:item.date="{ item }">
      <div>
        #{{ item.id }}
        <!-- <v-btn icon size="x-small" @click="copyToClipboard(item.id)">
          <v-icon>mdi-content-copy</v-icon>
        </v-btn> -->
      </div>
      <div>{{ item.updated_at }}</div>
    </template>
    <template v-slot:item.tr="{ item }">
      <div v-if="item.tr_hash"><a
        :href="'https://tronscan.org/#/transaction/' + item.tr_hash"
        target="_blank"
        >{{ item.tr_hash ? cutString(item.tr_hash, 25) + '...' : '' }}</a
      ></div>
      <div v-if="item.tr_hash">{{ (item.from_address && item.basis !== 'invoice')? item.from_address: (item.to_address)? item.wallet.address: item.to_address }}</div>
      <div v-if="!item.tr_hash">Пополнено менеджером</div>
    </template>
    <template v-slot:item.total="{ item }">
      <div>{{ item.value }} {{ item.currency.symbol }}</div>
      <div>
        <v-chip :color="getColor(item.basis)">
          {{ item.basis_name }}
        </v-chip>
      </div>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip :color="getColor(item.tr_status)">
        {{ item.tr_status_name }}
      </v-chip>
    </template>
  </v-data-table-server>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
  components: { NoData, },
  props: {
    state: {
      type: String,
      default: "active",
    }
  },
  data: () => ({
    search: '',
    itemsPerPage: 10,
    page: 1,
    headers: [
      {
        align: 'start',
        key: 'date',
        sortable: false,
        title: 'Дата',
      },
      { key: 'tr', title: 'Транзакция' },
      { key: 'total', title: 'Сумма' },
      { key: 'status', title: 'Статус' },
    ],
    desserts: [],
    totalItems: 0,
    loading: true,
    totalItems: 0,
  }),
  methods: {
    getColor(status) {
      let color = 'grey';
      switch (status) {
        case 'completed':
        case 'invoice':
        case "deposit_invoice":
          color = 'green';
          break;
        case 'withdrawal':
        case "deposit_withdrawal":
          color = 'grey';
          break;
        case 'waiting':
          color = 'yellow';
          break;
        case 'canceled':
          color = 'red';
          break;
      }
      return color;
    },

    getTransactions() {
      this.getDataList('/payments/tr/invoice-withdrawal-trs', {state: this.state, page_size: this.itemsPerPage, page: this.page}).then(data => {
        console.log(data);
        this.desserts = data.results;
        this.loading = false;
        this.totalItems = data.count;
      });
    }
  },
  mounted() {
    this.getTransactions()
  }
};
</script>
