<template>
  <div class="dialog" v-if="open">
    <div class="dialog-overlay" @click="close_dialog"></div>
    <div class="dialog-content py-6 px-4">
      <div class="mb-4">Детали</div>
      <div class="text-body-2">ID</div>
      <div class="text-body-2 mb-4">
        {{ details.id }} <v-icon
          @click="copyToClipboard(details.id)" 
          size="18" 
          color="blue"
        >mdi-clipboard-multiple-outline</v-icon>
      </div>
      <div class="text-body-2">Статус</div>
      <div class="text-body-2 mb-4">
        {{ order_data.order_status }} <v-icon 
          size="18" 
          color="blue"
        >mdi-robot</v-icon>
      </div>
      <div class="text-body-2">Сумма</div>
      <div class="text-body-2">{{ order_data.in_value }} {{ order_data.in_currency_symbol }}</div>
      <div class="text-body-2 mb-4">{{ order_data.out_value }} {{ order_data.out_currency_symbol }}</div>
      <div class="text-body-2">Вознаграждение трейдера</div>
      <div class="text-body-2 mb-4">{{ order_data.trader_reward }}</div>
      <div class="text-body-2">Реквизиты</div>
      <div class="font-weight-bold">{{ details.name }}</div>
      <div class="text-body-2">Перевод {{ details.tr_type.translated_name }}</div>
      <div class="text-subtitle-2 font-weight-regular" style="color: grey;">{{ details.bank.name }}</div>
      <div class="text-body-2">
        {{ details.card_number }} <v-icon
          @click="copyToClipboard(details.card_number)" 
          size="18" 
          color="grey"
        >mdi-clipboard-multiple-outline</v-icon>
      </div>
      <div class="text-body-2 mb-4">{{ details.owner }}</div>
      <div class="text-body-2">Дата подтверждения</div>
      <div class="text-body-2 mb-4">{{ order_data.updated_at }}</div>
      <div class="text-body-2">Квитанции</div>
      <div 
        @click="open_receipts_dialog" 
        class="text-subtitle-2 font-weight-regular" 
        style="color: blue; cursor: pointer;"
      >Открыть</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    order_data: {
      type: Object,
      default: null,
    },
    details: {
      type: Object,
      default: null,
    },
  },
  methods: {
    open_receipts_dialog() {
      this.$emit("open-receipts-dialog", this.order_data.receipts);
    },
    close_dialog() {
      this.$emit("close-dialog");
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
  },
};
</script>

<style scoped>
.dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 900;
  left: 0;
  top: 0;
}

.dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 902;
  width: inherit;
  height: inherit;
}

.dialog-content {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 910;
  max-width: 320px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-left: 2px solid #dedede;
  overflow: auto;
}

.dialog-content::-webkit-scrollbar {
  display: none;
}
</style>
