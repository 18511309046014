<template>
  <NoData />
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
  components: { NoData },
};
</script>
