<template>
    <v-table class="white-back" v-if="info">
        <thead>
        <tr>
            <th v-for="item in headers" class="text-left">
            {{ item.title }}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr >
            <!-- Столбец id -->
            <td>
                <div style="white-space: pre-line;">
                    <span>#{{ info.id }}</span><br>
                    <span class="text-subtitle-2 mini-date">{{ info.updated_at }}</span>
                </div>
            </td>
            <!-- Столбец rate -->
            <td>
                <div style="white-space: pre-line;">
                    <span>{{ info.rate.rate }} ₽</span><br>
                </div>
            </td>
            <!-- Столбец sum -->
            <td>
                <div style="white-space: pre-line;">
                    <span>{{ info.in_value }} {{ info.in_currency.symbol }}</span><br>
                    <v-chip color="green">{{ info.out_value }} {{ info.out_currency.symbol }}</v-chip>
                </div>
            </td>

            <!-- Столбец details -->
            <td>
                <div>
                    {{ info.details.name }}
                    {{ info.details.tr_type.name }}
                    {{ info.details.bank.name }}
                    {{ info.details.card_number }}
                    {{ info.details.validity_period }}
                    {{ info.details.phone_number }}
                    {{ info.details.owner }}
                </div>
            </td>

            <!-- Столбец payment -->
            <td>
                <div style="white-space: pre-line;">
                    <span>{{ info.tr_types.translated_name }}</span><br>
                </div>
            </td>

            <!-- Столбец status -->
            <td>
                <div style="white-space: pre-line;">
                    <span>{{ info.order_status }} {{ info.processed }} {{ info.cancel_status }}</span><br>
                </div>
            </td>
            
        </tr>
        </tbody>
  </v-table>
</template>

<script>
export default {
    components: {

    },
    props: {
        order_id: {
            type: Number,
            default: null,
            required: true
        },
    },
    data: () => ({
        headers: [
            { key: 'id', title: 'ID', align: 'start', sortable: false },
            { key: 'rate', title: 'Курс' },
            { key: 'sum', title: 'Сумма' },
            { key: 'details', title: 'Реквизиты' },
            { key: 'payment', title: 'Оплата' },
            { key: 'status', title: '' },
        ],
        info: null

    }),
    methods: {
        getOrder() {
            this.getDataList('payments/order/info/'+this.order_id+'/').then(data => {
              console.log("Order table. Data: ", data);
              this.info = data;
          });
        }
    },
    watch: {
        order_id: {
            immediate: true, // Чтобы вызвать getOrder сразу при монтировании
            handler(newVal) {
                if (newVal) {
                    this.getOrder();
                }
            }
        }
    },
    // created() {
    //     // Загружаем данные при создании компонента
    //     if (this.order_id) {
    //         this.getOrder();
    //     }
    // }
}
</script>
<style scoped>
.mini-date {
    color:dimgray;
    font-size: 0.25em;
    font-weight: 400;
    white-space: nowrap;
}
</style>