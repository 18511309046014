<template>
    <v-row>
        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="4" xxl="4">
            <v-card class="white-back">
                <v-card-title>
                    Добавить новую группу
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="name"
                        density="compact"
                        label="Название"
                        variant="outlined"
                    ></v-text-field>
                    <v-row>
                        <v-col cols="3">
                            <v-btn variant="outlined" color="error" @click="closeAdd">Отмена</v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" :disabled="false" @click="saveDetails">Сохранить</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    
</template>

<script>
export default {
    props: {
        update_id: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        name: null,
        save_disabled: true,
    }),
    mounted() {
        if (this.update_id !== null) {
            this.getGroupById(this.update_id);
        }
    },
    methods: {
        closeAdd() {
            this.$emit('close-add');
        },
        saveDetails() {
            if (!this.update_id) {
                this.saveData('trader/details/group/create/', {name: this.name})
            } else {
                this.updateData(`trader/details/group/${this.update_id}/update/`, {name: this.name})
                this.$emit("clear-update-id");
            }

            this.closeAdd()
        },
        getGroupById(id) {
            this.getDataList(`trader/details/group/${id}/`).then(data => {
                console.log("get data group table: ", data);

                this.name = data.name;
            });
        },
    },
}
</script>