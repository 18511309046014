<template>
    <NoData v-if="desserts.length === 0"/>
    <v-data-table
        v-else
        :headers="headers"
        :items="desserts"
        class="white-back"
    >
        <template v-slot:item.status="{ item }">
            <v-switch
                color="warning"
                hide-details
                v-model="item.status"
                @change="changeStatus(item.id)"
            ></v-switch>
        </template>
        <template v-slot:item.id="{ item }">
            <div style="white-space: pre-line;">
                <span>#{{ item.id }}</span><br>
                <span class="text-subtitle-2 mini-date">{{ item.updated_at }}</span>
            </div>
        </template>
        <template v-slot:item.details="{ item }">
            <div>
                {{ item.name }}
                {{ item.currency.name}}({{ item.currency.code }})
                {{ item.tr_type.name }}
                {{ item.bank.name }}
                {{ item.card_number }}
                {{ item.validity_period }}
                {{ item.phone_number }}
                {{ item.owner }}
            </div>
        </template>
        <template v-slot:item.quantity="{ item }">
            <div>{{ item.quantity_per_hour }}/{{ item.quantity_per_day }}/{{ item.quantity_per_month }}</div>
        </template>
        <template v-slot:item.amount="{ item }">
            <div>{{ item.amount_per_hour }}/{{ item.amount_per_day }}/{{ item.amount_per_month }}</div>
        </template>
        <template v-slot:item.group="{ item }">
            <div >{{ (item.group)? item.group.name : '-' }}</div>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-col>
                <v-row style="flex-wrap: nowrap;">
                    <v-btn color="transparent" elevation="0" icon size="small"><v-icon color="green" size="26">mdi-auto-mode</v-icon></v-btn>
                    <v-btn color="transparent" elevation="0" icon size="small" @click="swap_to_update_detail_table(item.id)">
                        <v-icon color="blue" size="26">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn @click="open_dialog(item.id)" color="transparent" elevation="0" icon size="small">
                        <v-icon color="red" size="26">mdi-delete</v-icon>
                    </v-btn>
                </v-row>
            </v-col>
        </template>
        <template v-slot:header.actions>
            <div class="text-center">Действия</div>
        </template>
        
    </v-data-table>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
    components: {
        NoData,
    },
    data: () => ({
        headers: [
            { key: 'status', title: 'Статус', align: 'start', sortable: false },
            { key: 'id', title: 'ID' },
            { key: 'details', title: 'Реквизиты' },
            { key: 'quantity', title: 'Количество(час/день/месяц)' },
            { key: 'amount', title: 'Сумма(час/день/месяц)' },
            { key: 'group', title: 'Группа' },
            { key: 'atomation', title: 'Способ автоматизации' },
            { key: 'orders_periodicity', title: 'Частота успешных ордеров' },
            { key: 'actions', title: 'Действия' },
        ],
        desserts: []
    }),
    methods: {
        getDetails() {
            this.getDataList('trader/details/').then(data => {
                console.log("Details table. Data: ", data);
                this.desserts = data;
            });
        },
        swap_to_update_detail_table(id) {
            // Передать detail id в DetailsVIew
            this.$emit("swap-to-update-detail-table", id);
        },
        open_dialog(id) {
            this.$emit("open-dialog", {
                open: true,
                what_delete: "details",
                id: id,
            });
        },
        changeStatus(detail_id) {
            this.saveData('trader/details/status_change/', {detail_id: detail_id}).then(data => {
                console.log("status changed", data)
            });
        }
    },
    mounted() {
        this.getDetails()
    }
}
  
</script>

<style scoped>
.mini-date {
    color:dimgray;
    font-size: 0.25em;
    font-weight: 400;
    white-space: nowrap;
}
</style>