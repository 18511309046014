<template>
    <v-container> 
        <div class="text-h6">Настройки</div>
        <v-divider></v-divider>
        <v-col>
            <v-row style="gap: 24px;">
                <v-col class="col">
                    <div class="text-h6 font-weight-regular mb-6">Личная информация</div>
                    <v-text-field 
                        label="Имя" 
                        variant="underlined"
                        density="compact"
                        hide-details
                        class="mb-4"
                        v-model="username"
                    ></v-text-field>
                    <v-text-field 
                        label="Email" 
                        variant="underlined"
                        density="compact"
                        hide-details
                        class="mb-4"
                        v-model="email"
                    ></v-text-field>
                    <v-text-field 
                        label="Телеграм" 
                        variant="underlined"
                        density="compact"
                        hide-details
                        class="mb-4"
                        v-model="telegram"
                    ></v-text-field>
                    <v-checkbox 
                        label="Изменить пароль" 
                        density="compact"
                        hide-details
                        class="mb-4 text-body-2"
                        v-model="status_change_password"
                    ></v-checkbox>
                    <v-btn variant="outlined" color="primary" class="text-none">Сохранить</v-btn>

                    <v-divider class="my-6"></v-divider>

                    <div class="text-h6 font-weight-regular mb-4">Нотификации</div>

                    <div class="mb-4">
                        <div class="text-subtitle-1 mb-2">Телеграм</div>
                        <div class="d-flex align-center text-subtitle-2">
                            Статус:
                            <v-chip 
                                style="font-size: 0.9em;" 
                                class="ml-1" 
                                density="compact"
                            >Выключено</v-chip>
                        </div>
                        <router-link 
                            class="text-body-2 text-decoration-none" 
                            to="/"
                        >Подключить аккаунт</router-link>
                    </div>

                    <div>
                        <div class="text-subtitle-1 mb-2">События</div>
                        <div class="d-flex align-center text-subtitle-2">Запросы средств</div>
                        <v-checkbox 
                            label="Создание заявки на ввод/вывод" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Завершение заявки на ввод/вывод" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <div class="d-flex align-center text-subtitle-2 mt-2">PayIn ордера</div>
                        <v-checkbox 
                            label="Получен ордер" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер отменен" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен в успех" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер оплачен клиентом" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен в диспут" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер создан менеджером и переведен в диспут" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Успешный ордер переведен в отмену" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен в диспут. Закончилось время подтверждения оплаты" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер отменен. Закончилось время подтверждения оплаты" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер подтвержден автоматикой" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен из диспута в успех" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен из диспута в отмену" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Реквизиты заблокированы" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Реквизиты разблокированы" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <div class="d-flex align-center text-subtitle-2 mt-2">PayOut ордера</div>
                        <v-checkbox 
                            label="Получен ордер" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Закончилось время подтверждения принятия" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Закончилось время подтверждения оплаты" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен в диспут" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер создан менеджером и переведен в диспут" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер отменен" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Ордер переведен в успех" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Реквизиты заблокированы" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Реквизиты разблокированы" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <div class="d-flex align-center text-subtitle-2 mt-2">Автоматизация</div>
                        <v-checkbox 
                            label="Реквизиты отключены, если нет доступа к автоматике" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                        <v-checkbox 
                            label="Автоматизация через телеграм отключена" 
                            density="compact"
                            hide-details
                            class="text-body-2"
                        ></v-checkbox>
                    </div>
                    <v-btn variant="outlined" color="primary" class="mt-4 text-none">Сохранить</v-btn>
                </v-col>
                <v-col class="col">
                    <div class="text-h6 font-weight-regular mb-4">Двойная аутентификация</div>
                    <v-switch 
                        label="Выключено" 
                        density="compact"
                        hide-details
                        class="text-body-2"
                    ></v-switch>

                    <div class="my-4">
                        <div class="d-flex align-center text-subtitle-2">Секретный код</div>
                        <div 
                            style="font-size: 0.75em;" 
                            @click="copyToClipboard(secretKey)"
                        >{{ secretKey }}</div>
                    </div>

                    <div>
                        <div class="d-flex align-center text-subtitle-2">QR Код</div>
                        <div class="qr-code">
                            <v-img src="@/assets/qr-image/qr-code.png" alt="qr" />
                        </div>
                    </div>

                    <v-btn variant="outlined" color="red" class="mt-4 text-none">Сбросить секретный код</v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-container>
    <!-- <SuccessfulCopyDialog 
        :open="openSuccessfulCopyDialog" 
        :error="copyError" 
        :isCopied="isCopied" 
        @close-dialog="close_dialog"
    /> -->
</template>

<script>
export default {
    // components: { SuccessfulCopyDialog },
    data: () => ({
        username: "Goodman",
        email: "example@gmail.com",
        telegram: "",
        status_change_password: false,
        openSuccessfulCopyDialog: false,
        copyError: false,
        isCopied: "",
        secretKey: "OVREIJKEK5UDW5Z2PVKESKLVGJHVO5KXJA5VI5CAHJYFIWSOPJQQ",
    }),
    methods: {
        // close_dialog() {
        //     this.openSuccessfulCopyDialog = false;
        //     this.copyError = false;
        // },
        copyToClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.isCopied = "Секретный код";
                // this.openSuccessfulCopyDialog = true;
            }).catch((error) => {
                this.copyError = true;
            });
        },
    },
};
</script>

<style scoped>
.col {
    min-width: 360px;
}

.qr-code {
    width: 198px;
    height: 198px;
}
.qr-code > img {
    height: 100%;
    width: 100%;
}
</style>
