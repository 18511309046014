<template>
  <NoData v-if="desserts.length === 0"/>
  <v-data-table-server
        v-else
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="desserts"
        :items-length="totalItems"
        :loading="loading"
        @update:options="getTransactions"
        :page="page"
        class="white-back"
        :items-per-page-options="[5, 10, 20, 50, 100]"
  >
    <template v-slot:item.code="{ item }">
        <div>{{ item.tr_hash }}</div>
    </template>
    <template v-slot:item.value="{ item }">
        <div>{{ Math.abs(item.value) }} {{ item.currency.symbol }}</div>
    </template>
    <template v-slot:item.status="{ item }">
        <div>{{ (!item.status)? "Активен": "Выполнен" }}</div>
    </template>
    <template v-slot:item.actions="{ item }">
        <div><v-btn variant="outlined" color="red" @click="deleteTr(item.id)">Отмена</v-btn></div>
    </template>
  </v-data-table-server>
</template>

<script>
import NoData from '@/components/alerts/NoData.vue';

export default {
  components: { NoData, },
  props: {
    state: {
      type: String,
      default: "active",
    }
  },
  data: () => ({
    itemsPerPage: 10,
    page: 1,
    headers: [
      {
        align: 'start',
        key: 'code',
        sortable: false,
        title: 'Код внутреннего перевода',
      },
      { key: 'updated_at', title: 'Дата' },
      { key: 'value', title: 'Сумма' },
      { key: 'status', title: 'Статус' },
      { key: 'user_name', title: 'Отправитель' },
    ],
    desserts: [],
    totalItems: 0,
    loading: true,
    totalItems: 0,
  }),
  mounted() {
    if (this.state === 'active') {
      this.headers.push({ key: 'actions', title: 'Действия', sortable: false })
    }
    this.getTransactions()
  },
  methods: {
    getTransactions() {
      this.getDataList('/payments/trs', {internal: this.state, page_size: this.itemsPerPage, page: this.page}).then(data => {
        console.log(data);
        this.desserts = data.results;
        this.loading = false;
        this.totalItems = data.count;
      });
    },
    deleteTr(tr_id) {
      this.saveData('payments/internal/delete/', {tr_id: tr_id }).then(data => {
        console.log(data);
        this.getTransactions()
      });
    }
  }
};
</script>
