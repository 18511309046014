<template>
  <v-app>
    <HomeView />
  </v-app>
</template>

<script>

import HomeView from './views/HomeView.vue';

export default {
  name: 'App',

  components: {
    HomeView
  },

  data: () => ({
    //
  }),
}
</script>

<style>
.custom-surface {
  background-color: var(--v-theme-surface);
  color: white; 
}
.white-back {
    background-color: #ffffff;
}
.white-text {
  color: #ffffff!important;
}
.v-selection-control__input > .v-icon {
  opacity: 1 !important;
}
.v-label {
  opacity: 1 !important;
}
.v-checkbox .v-selection-control {
    min-height: auto;
}
.nodata-small > *:last-child > div {
  font-size: 16px;
}
</style>