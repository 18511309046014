<template>
    <v-container fluid>
        <v-card class="ma-auto card-merch" v-if="!order">
            <v-card-title class="text-center">
                Тестовый эмулятор мерчанта
            </v-card-title>
            <v-card-text>
                <v-text-field
                        v-model="api_key"
                        density="compact"
                        label="Api Key мерчанта"
                        variant="outlined"
                ></v-text-field>
                <v-select
                    v-if="api_key && api_key.length === 32"
                    v-model="currency_id"
                    :items="currencies"
                    :item-title="(item) => `${item.name} (${item.code})`"
                    item-value="id"
                    label="Валюта*"
                    variant="outlined"
                    density="compact"
                    required
                ></v-select>
                <v-select
                    v-if="currency_id"
                    v-model="tr_type_name"
                    :items="tr_types"
                    item-title="translated_name"
                    item-value="name"
                    label="Способ пополнения*"
                    variant="outlined"
                    density="compact"
                    required
                ></v-select>
                <v-autocomplete
                    v-if="tr_type_name"
                    v-model="bank_id"
                    :items="banks"
                    item-title="name"
                    item-value="id"
                    label="Банк*"
                    variant="outlined"
                    density="compact"
                    required
                ></v-autocomplete>
                <v-text-field
                    v-if="bank_id"
                    v-model="amount"
                    density="compact"
                    label="Сумма"
                    variant="outlined"
                ></v-text-field>
                <v-btn v-if="amount" color="warning" @click="sendOrder">Оплатить</v-btn>
            </v-card-text>
        </v-card>
        <v-card v-else class="ma-auto card-merch">

            <v-card-title>
                Оплата
            </v-card-title>
            <v-card-text>
                <div class="text-h5">Оплатите на следующие реквизиты:</div>
                <div><span class="font-weight-bold">Валюта:</span> {{ order.currency }}</div>
                <div><span class="font-weight-bold">Тип оплаты:</span> {{ order.tr_type_name }}</div>
                <div><span class="font-weight-bold">Бакн:</span> {{ order.bank_name }}</div>
                <div><span class="font-weight-bold">Оплатить на:</span> {{ order.pay_to }}</div>
                <div><span class="font-weight-bold">Владелец:</span> {{ order.owner }}</div>
                <div><span class="font-weight-bold">Сумма:</span> {{ order.amount }}</div>
                <div><span class="font-weight-bold">Курс:</span> {{ order.rate }}</div>
                <div><span class="font-weight-bold">Получите:</span> {{ order.crypto_amount }}</div>
                <div><span class="font-weight-bold">Оплатить до:</span> {{ order.expires }}</div>
                <div><span class="font-weight-bold">Статус:</span> <v-chip :color="status_color">{{ status_name }}</v-chip></div>
                <v-btn color="warning" class="mt-3" v-if="order.status === 'waiting'" @click="checkOrderStatus"> Оплатил, проверить оплату</v-btn>
            </v-card-text>
        </v-card>


        <v-dialog
            v-model="dialog"
            width="auto"
        >
            <v-card
                max-width="300"
                prepend-icon="mdi-content-copy"
                :text="dialog_text"
                title="Ошибка"
            >
                <template v-slot:actions>
                <v-btn
                    class="ms-auto"
                    text="Ok"
                    @click="dialog = false"
                ></v-btn>
                </template>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import axios from 'axios';

export default {
    data: () => ({
        api_key: null,
        currencies: [],
        tr_types: [],
        banks: [],
        currency_id: null,
        tr_type_name: null,
        bank_id: null,
        amount: null,
        order: null,
        dialog: false,
        dialog_text: null,
        status_name: null,
        status_color: null
    }),
    methods: {
        getCurrencies() {
            axios.get('http://127.0.0.1:8002/api/v1/catalogs/currencies/', {
                headers: {"Api-Key": this.api_key}
            }).then(response => {
                console.log(response)
                this.currencies = response.data
            })
            .catch(error => {
                console.error(error);
                this.dialog_text = "Неверный Api Key!"
                this.dialog = true
            });
        },
        getTrTypes() {
            axios.get('http://127.0.0.1:8002/api/v1/catalogs/tr_types', {
                headers: {"Api-Key": this.api_key},
                params: {currency: this.currency_id}
            }).then(response => {
                console.log(response)
                this.tr_types = response.data
            })
            .catch(error => {
                console.error(error);
                this.dialog_text = "Неверный Api Key!"
                this.dialog = true
            });
        },
        getBanks() {
            axios.get('http://127.0.0.1:8002/api/v1/catalogs/banks', {
                headers: {"Api-Key": this.api_key},
                params: {currency: this.currency_id, tr_type: this.tr_type_name}
            }).then(response => {
                console.log(response)
                this.banks = response.data
            })
            .catch(error => {
                console.error(error);
                this.dialog_text = "Неверный Api Key!"
                this.dialog = true
            });
        },
        sendOrder() {
            axios.post('http://127.0.0.1:8002/api/v1/payments/order/create/', {
                headers: {"Api-Key": this.api_key},
                data: {
                    currency: this.currency_id, 
                    tr_type: this.tr_type_name,
                    bank_id: this.bank_id,
                    amount: this.amount
                }
            }).then(response => {
                console.log(response)
                if (response.data.status) {
                    this.order = response.data.result
                    this.statusName(response.data.result.status)
                } else {
                    this.dialog_text = response.data.error
                    this.dialog = true
                }
                
            })
            .catch(error => {
                console.error(error);
                this.dialog_text = "Неверный Api Key!"
                this.dialog = true
            });
        },
        statusName(status) {
            switch (status) {
                case 'waiting':
                    this.status_name = "Ожидает оплату"
                    this.status_color = "yellow"
                    break;
                case 'completed':
                    this.status_name = "Выполнен"
                    this.status_color = "#01440f"
                    break;
                case 'canceled':
                    this.status_name = "Отменен"
                    this.status_color = "red"
                    break;
            }
        },
        checkOrderStatus()  {
            axios.get('http://127.0.0.1:8002/api/v1/payments/order/status/', {
                headers: {"Api-Key": this.api_key},
                params: {order_id: this.order.order_id}
            }).then(response => {
                console.log(response)
                this.order.status = response.data.result.status
                this.statusName(response.data.result.status)
            })
            .catch(error => {
                console.error(error);
                this.dialog_text = "Неверный Api Key!"
                this.dialog = true
            });
        }
        
    },
    mounted() {
        // this.statusName(this.order.status)
    },
    computed: {

    },
    watch: {
        api_key() {
            if (this.api_key.length === 32) {
                this.getCurrencies()
            }
        },
        currency_id() {
            if (this.api_key.length === 32) {
                this.getTrTypes()
            }
        },
        tr_type_name() {
            if (this.api_key.length === 32) {
                this.getBanks()
            }
        }
    }
}
</script>

<style scoped>
.card-merch {
    width: 40%;
}
@media (max-width: 800px) {
    .card-merch {
        width: 90%;
    }
}
</style>