<template>
    <v-container> 
        <div class="text-h6">Вводы/Выводы</div>
        <v-divider></v-divider>
        <v-tabs
            v-model="tab"
            align-tabs="left"
            color="primary"
            >
            <v-tab :value="1">Активные</v-tab>
            <v-tab :value="2">Архивные</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item key="1" :value="1">
                <v-card flat color="white" class="ma-2">
                    <WithdrawalTable state="active"/>
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item key="2" :value="2">
                <v-card flat color="white" class="ma-2">
                    <WithdrawalTable state="archive"/>
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
        
    </v-container>
</template>

<script>
import WithdrawalTable from '@/components/withdrawal/WithdrawalTable.vue';

  export default {
    components: {
        WithdrawalTable,
    },
    data: () => ({
      tab: null,
    }),
  }
</script>
