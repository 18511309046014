<template>
  <v-dialog max-width="320" v-model="localOpen">
    <v-card color="white" class="card py-6 ma-auto">
      <v-col>
        <v-row class="align-center justify-center flex-column">
          <v-icon :color="error ? 'red' : 'lime'" class="icon mb-4">mdi-check-circle</v-icon>
          <div v-if="error" class="text-center message-text">Не удалось скопировать в буфер обмена</div>
          <div v-else class="text-center message-text"><span style="font-size: 12px;">{{ isCopied }}</span> <br /> Скопирован в буфер обмена</div>
        </v-row>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    timeout: null,
  }),
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    isCopied: {
      type: String,
      default: "Undefined",
    },
  },
  watch: {
    open(value) {
      if (value) {
        this.timeout = setTimeout(() => {
          this.localOpen = false;
        }, 2000);
      } else {
        clearTimeout(this.timeout);
      }
    }
  },
  computed: {
    localOpen: {
      get() {
        return this.open;
      },
      set() {
        this.$emit("close-dialog");
      },
    },
  },
};
</script>

<style scoped>
.card {
  border-radius: 10px !important;
  width: 320px;
}

.icon {
  font-size: 56px;
}

.message-text {
  line-height: 24px;
  font-size: 18px;
}
</style>
