<template>
    <v-row>
        <v-col cols="6">
            <div>Email: {{ merchant.email }}</div>
            <div>Имя: {{ merchant.first_name }}</div>
            <div>Фамилия: {{ merchant.last_name }}</div>
            <div>Дата регистрации: {{ merchant.date_joined }}</div>
            <div>Дата последнего входа: {{ merchant.last_login }}</div>
            <div>Телеграм: @{{ merchant.telegram_username }}</div>
            <div>Телефон: {{ merchant.phone }}</div>
            <div>Всего диспутов: {{ merchant.phone }}</div>
            <div>Диспутов в пользу: {{ merchant.phone }}</div>
            <div>Диспутов в против: {{ merchant.phone }}</div>
        </v-col>
        <v-col cols="6">
            <div>Email: {{ trader.email }}</div>
            <div>Имя: {{ trader.first_name }}</div>
            <div>Фамилия: {{ trader.last_name }}</div>
            <div>Дата регистрации: {{ trader.date_joined }}</div>
            <div>Дата последнего входа: {{ trader.last_login }}</div>
            <div>Телеграм: @{{ trader.telegram_username }}</div>
            <div>Телефон: {{ trader.phone }}</div>
            <div>Всего диспутов: {{ trader.phone }}</div>
            <div>Диспутов в пользу: {{ trader.phone }}</div>
            <div>Диспутов в против: {{ trader.phone }}</div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    components: {

    },
    props: {
        merchant: {
            type: Object,
            default: null,
            required: true
        },
        trader: {
            type: Object,
            default: null,
            required: true
        },
    },
    data: () => ({
        
    }),



}
</script>