<template>
  <NoData v-if="desserts.length === 0"/>
  <v-data-table-server 
    v-else
    v-model:items-per-page="itemsPerPage"
    :headers="headers"
    :items="desserts"
    :items-length="totalItems"
    :loading="loading"
    @update:options="getAutomation"
    :page="page"
    class="white-back"
    :items-per-page-options="[5, 10, 20, 50, 100]"
  > 
    <!-- Основной слот для строки -->
    <template v-slot:item="{ item }">
      <tr>
        <!-- Столбец id -->
        <td style="width: 140px;">
          <div style="width: inherit;">
            <span>#{{ item.id }}</span><br>
            <span class="mini-date">{{ item.updated_at }}</span>
          </div>
        </td>

        <!-- Столбец app -->
        <td>
          <div>
            <span>{{ item.app }}</span>
          </div>
        </td>

        <!-- Столбец title -->
        <td>
          <div>
            <span>{{ item.title }}</span>
          </div>
        </td>

        <!-- Столбец message -->
        <td>
          <div class="d-flex align-center">
            <div class="mr-2">{{ item.message }}</div>
            <v-icon 
              @click="copyToClipboard(item.message)"
              size="18" 
              color="blue"
            >mdi-clipboard-multiple-outline</v-icon>
          </div>
        </td>

        <!-- Столбец способ автоматизации -->
        <td>
          Empty
        </td>
      </tr>
    </template>
  </v-data-table-server>
</template>

<script>
import NoData from "@/components/alerts/NoData.vue";

export default {
  components: { NoData },
  data: () => ({
    itemsPerPage: 10,
    page: 1,
    headers: [
      { key: "id", title: "ID", align: "start", sortable: false },
      { key: "app", title: "Приложение" },
      { key: "title", title: "Заголовок" },
      { key: "message", title: "Текст сообщения" },
      { key: "method", title: "Способ автоматизации" },
    ],
    desserts: [],
    totalItems: 0,
    loading: true,
    totalItems: 0,
  }),
  props: {
    state: {
      type: String,
      default: "active",
    },
  },
  methods: {
    getAutomation() {
      this.getDataList("sms/automation", { state: this.state }).then(data => {
        console.log("Automation data: ", data);
        this.desserts = data.results;
        this.loading = false;
        this.totalItems = data.count;
      });
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
    },
  },
  mounted() {
    this.getAutomation();
  },
};
</script>

<style scoped>
.mini-date {
  color:dimgray;
  font-size: 14px;
  font-weight: 400;
}
</style>
