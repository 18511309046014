<template>
    <v-row>
        <v-col cols="6" xs="12" sm="12" md="6" lg="6" xl="4" xxl="4">
            <v-card class="white-back">
                <v-card-title>
                    Добавить новые реквизиты
                </v-card-title>
                <v-card-text>
                    <v-text-field
                        v-model="details.name"
                        density="compact"
                        label="Название"
                        variant="outlined"
                    ></v-text-field>
                    <v-select
                        v-model="details.group_id"
                        :items="groups"
                        item-title="name"
                        item-value="id"
                        label="Группа*"
                        variant="outlined"
                        density="compact"
                        required
                    ></v-select>
                    <v-select
                        v-model="details.currency_id"
                        :items="currencies"
                        :item-title="(item) => `${item.name} (${item.code})`"
                        item-value="id"
                        label="Валюта*"
                        variant="outlined"
                        density="compact"
                        required
                    ></v-select>
                    <v-select
                        v-if="details.currency_id"
                        v-model="details.tr_type_name"
                        :items="tr_types"
                        item-title="translated_name"
                        item-value="name"
                        label="Способ пополнения*"
                        variant="outlined"
                        density="compact"
                        required
                    ></v-select>
                    <v-autocomplete
                        v-if="details.tr_type_name"
                        v-model="details.bank_id"
                        :items="banks"
                        item-title="name"
                        item-value="id"
                        label="Банк*"
                        variant="outlined"
                        density="compact"
                        required
                    ></v-autocomplete>
                    <!-- account number -->
                    <v-text-field
                        v-if="account_number_view"
                        v-model="details.account_number"
                        density="compact"
                        label="Номер счета*"
                        variant="outlined"
                        required
                    ></v-text-field>
                    <!-- account number -->
                    <!-- phone number & sbp -->
                    <v-text-field
                        v-if="phone_number_view"
                        v-model="details.phone_number"
                        density="compact"
                        label="Номер телефона*"
                        variant="outlined"
                        required
                    ></v-text-field>
                    <!-- phone number & sbp -->
                    <!-- card number -->
                    <v-text-field
                        v-if="card_number_view"
                        v-model="details.card_number"
                        density="compact"
                        label="Номер карты*"
                        variant="outlined"
                        :rules="cardNumberRules"
                        :class="{'mb-4': !cardNumberRules.every(rule => rule(details.card_number) === true)}"
                        @input="details.card_number = details.card_number.replace(/\s+/g, '');"
                        required
                    ></v-text-field>
                    <!-- card number -->
                    <v-text-field
                        v-if="details.bank_id"
                        v-model="details.owner"
                        density="compact"
                        label="Владелец реквизитов"
                        variant="outlined"
                        :rules="ownerRules"
                        :class="{'mb-4': !ownerRules.every(rule => rule(details.owner) === true)}"
                        @input="details.owner = details.owner.toUpperCase()"
                    ></v-text-field>
                    <v-text-field
                        v-if="account_last_numbers_view"
                        v-model="details.account_last_numbers"
                        density="compact"
                        label="Последние 4 цифры счета (автоматика)"
                        variant="outlined"
                    ></v-text-field>
                    <v-text-field
                        v-if="validity_period_view"
                        v-model="details.validity_period"
                        density="compact"
                        label="Срок действия"
                        variant="outlined"
                        :rules="validityPeriodRules"
                        :class="{'mb-4': !validityPeriodRules.every(rule => rule(details.validity_period) === true)}"
                        @input="formatDate($event)"
                    ></v-text-field>
                    <!-- quantity -->
                    <v-row>
                        <v-col cols="4" class="pb-0">
                            <v-text-field
                                v-model="details.quantity_per_hour"
                                density="compact"
                                label="Количество в час*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <v-text-field
                                v-model="details.quantity_per_day"
                                density="compact"
                                label="Количество в день*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pb-0">
                            <v-text-field
                                v-model="details.quantity_per_month"
                                density="compact"
                                label="Количество в месяц*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- quantity -->
                    <!-- amount -->
                    <v-row class="pt-0">
                        <v-col cols="4" class="pt-0">
                            <v-text-field
                                v-model="details.amount_per_hour"
                                density="compact"
                                label="Сумма в час*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pt-0">
                            <v-text-field
                                v-model="details.amount_per_day"
                                density="compact"
                                label="Сумма в день*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="4" class="pt-0">
                            <v-text-field
                                v-model="details.amount_per_month"
                                density="compact"
                                label="Сумма в месяц*"
                                variant="outlined"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <!-- amount -->
                    <v-text-field
                        v-model="details.active_orders"
                        density="compact"
                        label="Одновременное число активных ордеров*"
                        variant="outlined"
                    ></v-text-field>
                    <v-text-field
                        v-model="details.orders_periodicity"
                        density="compact"
                        label="Число успешных ордеров (1 успешный / Х минут)*"
                        variant="outlined"
                        hint="Cледующие ордера будут назначаться на данный реквизит через X минут после последнего успешного"
                        persistent-hint
                    ></v-text-field>
                    <v-text-field
                        v-model="details.minimal_range"
                        density="compact"
                        label="Минимальный курс"
                        variant="outlined"
                    ></v-text-field>
                        <v-radio-group v-model="details.status">
                            <v-row class="mt-3">
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="3"><v-radio :value="true"></v-radio></v-col>
                                        <v-col cols="9" class="align-content-center"><v-chip color="green">Активен</v-chip></v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="4">
                                    <v-row>
                                        <v-col cols="3"><v-radio :value="false"></v-radio></v-col>
                                        <v-col cols="9" class="align-content-center"><v-chip color="grey">Неактивен</v-chip></v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-radio-group>
                    <v-row>
                        <v-col cols="3">
                            <v-btn variant="outlined" color="error" @click="closeAdd">Отмена</v-btn>
                        </v-col>
                        <v-col cols="3">
                            <v-btn color="primary" :disabled="false" @click="saveDetails">Сохранить</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        update_id: {
            type: Number,
            default: null,
        },
    },
    data: () => ({
        groups: [],
        currencies: [],
        tr_types: [],
        banks: [],
        details: {
            name: null,
            group_id: null,
            currency_id: null,
            tr_type_name: null,
            bank_id: null,
            account_number: null,
            phone_number: null,
            card_number: null,
            owner: null,
            account_last_numbers: null,
            validity_period: null,
            quantity_per_hour: 1000,
            quantity_per_day: 10000,
            quantity_per_month: 100000,
            amount_per_hour: 10000000,
            amount_per_day: 100000000,
            amount_per_month: 1000000000,
            active_orders: 100,
            orders_periodicity: 0,
            status: false,
            minimal_range: 90,
        },
        account_number_view: false,
        phone_number_view: false,
        card_number_view: false,
        account_last_numbers_view: false,
        validity_period_view: false,
        save_disabled: true,

        cardNumberRules: [
            v => !!v || "Номер карты обязателен",
            v => /^\d{16,}$/.test(v) || "Неверный номер карты"
        ],
        ownerRules: [
            v => !v || /^[A-Za-zА-Яа-яЁё\s]{4,}$/.test(v) || "Поле должно содержать только буквы, минимум 4 символа"
        ],
        validityPeriodRules: [
            v => !!v || 'Срок действия обязателен',
            v => /^(0[1-9]|1[0-2])\/\d{2}$/.test(v) || 'Формат должен быть ММ/ГГ'
        ],
    }),
    methods: {
        formatDate(event) {
            if (!event || !event.target) return;

            let input = event.target.value.replace(/\D/g, '');

            if (input.length >= 4) {
                const month = input.slice(0, 2);
                const year = input.slice(2, 4);
        
                if (parseInt(month, 10) > 12) {
                    input = `12/${year}`;
                } else {
                    input = `${month}/${year}`;
                }
            }

            this.details.validity_period = input;

            if (input.length < 5) {
                this.$nextTick(() => {
                    event.target.selectionStart = event.target.selectionEnd = input.length;
                });
            }
        },
        closeAdd() {
            this.$emit('close-add');
        },
        showFieldsByTrType(tr_type_name) {
            switch (tr_type_name) {
                case 'card2card':
                    this.account_number_view = false;
                    this.card_number_view = true;
                    this.validity_period_view = true;
                    this.phone_number_view = false;
                    this.account_last_numbers_view = false;
                    break;
                case 'sbp':
                case 'phone':
                    this.account_number_view = false;
                    this.card_number_view = false;
                    this.validity_period_view = false;
                    this.phone_number_view = true;
                    this.account_last_numbers_view = true;
                    break;
                case 'account':
                    this.account_number_view = true;
                    this.card_number_view = false;
                    this.validity_period_view = false;
                    this.phone_number_view = false;
                    this.account_last_numbers_view = true;
                    break;
                default:
                    this.account_number_view = false;
                    this.card_number_view = false;
                    this.validity_period_view = false;
                    this.phone_number_view = false;
                    this.account_last_numbers_view = false;
            }
        },
        saveDetails() {
            if (!this.update_id) {
                this.saveData('trader/detail/create/', this.details)
            } else {
                this.updateData(`trader/detail/${this.update_id}/update/`, this.details)
                this.$emit("clear-update-id");
            } 

            this.closeAdd()
        },
        getDetailsById(id) {
            this.getDataList(`trader/detail/${id}/`).then(data => {
                console.log("get data by id: ", data);

                this.details.name = data.name;
                this.details.group_id = data.group.id;
                this.details.phone_number = data.phone_number;
                this.details.card_number = data.card_number;
                this.details.owner = data.owner;
                this.details.account_last_numbers = data.account_last_numbers;
                this.details.validity_period = data.validity_period;
                this.details.quantity_per_day = data.quantity_per_day;
                this.details.quantity_per_hour = data.quantity_per_hour;
                this.details.quantity_per_month = data.quantity_per_month;
                this.details.amount_per_day = data.amount_per_day;
                this.details.amount_per_hour = data.amount_per_hour;
                this.details.amount_per_month = data.amount_per_month;
                this.details.active_orders = data.active_orders;
                this.details.orders_periodicity = data.orders_periodicity;
                this.details.status = data.status;

                if (data.currency) {
                    this.details.currency_id = data.currency.id;
                }

                if (data.tr_type) {
                    this.details.tr_type_name = data.tr_type.name;
                }

                if (data.bank) {
                    this.details.bank_id = data.bank.id
                }
            });
        }
    },
    mounted() {
        this.getDataList('trader/details/groups/').then(data => {
            console.log(data);
            this.groups = data;
        });

        this.getDataList('catalogs/currencies/').then(data => {
            console.log(data);
            this.currencies = data;
        });

        if (this.update_id !== null) {
            this.getDetailsById(this.update_id);
        }
    },
    computed: {
        saveDisabled() {
            return !(this.details.currency_id && this.details.tr_type_name && this.details.bank_id 
            && this.details.quantity_per_hour && this.details.quantity_per_day && this.details.quantity_per_month 
            && this.details.amount_per_hour && this.details.amount_per_day && this.details.amount_per_month
            && this.details.active_orders && this.details.orders_periodicity 
            && ((this.details.account_number && this.details.account_number !== '') || (this.details.phone_number && this.details.phone_number !== '') 
            || (this.details.card_number && this.details.card_number !== '')))
        }
    },
    watch: {
        'details.currency_id': function() {
            if (!this.update_id) {
                this.details.tr_type_name = null
                this.details.bank_id = null
                this.details.account_number = null
                this.details.phone_number = null
                this.details.card_number = null
                this.details.owner = null
                this.details.account_last_numbers = null
                this.details.validity_period = null
                this.details.quantity_per_hour = 1000
                this.details.quantity_per_day = 10000
                this.details.quantity_per_month = 100000
                this.details.amount_per_hour = 10000000
                this.details.amount_per_day = 100000000
                this.details.amount_per_month = 1000000000
                this.details.active_orders = 100
                this.details.orders_periodicity = 0
                this.details.status = false
            }

            this.getDataList('catalogs/tr_types', {currency: this.details.currency_id}).then(data => {
                console.log(data);
                this.tr_types = data;
            });
        },
        'details.tr_type_name': function() {
            this.showFieldsByTrType(this.details.tr_type_name);

            if (!this.update_id) {
                this.details.bank_id = null
                this.details.account_number = null
                this.details.phone_number = null
                this.details.card_number = null
                this.details.owner = null
                this.details.account_last_numbers = null
                this.details.validity_period = null
                this.details.quantity_per_hour = 1000
                this.details.quantity_per_day = 10000
                this.details.quantity_per_month = 100000
                this.details.amount_per_hour = 10000000
                this.details.amount_per_day = 100000000
                this.details.amount_per_month = 1000000000
                this.details.active_orders = 100
                this.details.orders_periodicity = 0
                this.details.status = false
            }

            if (this.details.tr_type_name) {
                this.getDataList('catalogs/banks', {currency: this.details.currency_id, tr_type: this.details.tr_type_name}).then(data => {
                    console.log(data);
                    this.banks = data;
                });
            }
            
        }
    }
}
</script>