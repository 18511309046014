<template>
    
      <v-bottom-navigation
        v-model="value"
        color="red"
        fixed
        class="mobile_nav_menu"
      >
        <v-btn v-for="item in mainMenu" class="bottom-nav-btn text-white" :to="item.link">
          <v-icon size="large">{{ item.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>

</template>

<script>
  export default {
    data: () => ({ 
        value: 1,
        mainMenu: [
            { title: 'Балансы', icon: 'mdi-wallet', link: "/"},
            { title: 'Финансы', icon: 'mdi-finance', link: "/finance"},
            { title: 'Управление', icon: 'mdi-gamepad-circle', link: "/control" },
            { title: 'Статистика', icon: 'mdi-chart-areaspline', link: "/statistics" },
            { title: 'Настройки', icon: 'mdi-cog', link: "/settings" },
        ],

        menuItems: [
          { title: 'Балансы', icon: 'mdi-wallet', link: "/"},
          { title: 'Запросы средств', icon: 'mdi-cash', link: "/withdrawals" },
          { title: 'Внутренние переводы', icon: 'mdi-bank-transfer', link: "/internal" },
          { title: 'Изменения баланса', icon: 'mdi-finance', link: "/balancechange" },
        ],
        menuItemsDefault: [
          { title: 'Реквизиты', icon: 'mdi-credit-card' },
          { title: 'Ордера', icon: 'mdi-tray-plus' },
          { title: 'Автоматизация', icon: 'mdi-brightness-auto', link: '/automation' },
          { title: 'Реферальная система', icon: 'mdi-account-switch' },
          { title: 'Статистика', icon: 'mdi-chart-areaspline', link: '/statistics' },
        ],
    }),
  }
</script>
<style scoped>
.bottom-nav-btn {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  
}
.mobile_nav_menu {
    background-color: cornflowerblue;
}
</style>