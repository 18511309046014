<template>
    <v-container>
        <div class="text-h6">Внутренние переводы</div>
        <v-divider class="mb-2"></v-divider>
        <v-row class="ma-2">
            <v-col>
                <v-btn color="primary" @click="outTrDialog = true">Создать</v-btn>
                <v-btn color="primary" @click="incomeTrDialog = true" class="ml-2">Принять</v-btn>
            </v-col>
        </v-row>
        <v-tabs
            v-model="tab"
            align-tabs="left"
            color="primary"
            >
            <v-tab :value="1">Активные</v-tab>
            <v-tab :value="2">Архивные</v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item key="1" :value="1">
                <v-card
                flat
                class="ma-2"
                >
                    <InternalTrTable ref="activeInternalTrTableComponent" state="active"/>
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
        <v-tabs-window v-model="tab">
            <v-tabs-window-item key="2" :value="2">
                <v-card
                flat
                class="ma-2"
                >
                    <InternalTrTable ref="archiveInternalTrTableComponent" state="archive"/>
                </v-card>
            </v-tabs-window-item>
        </v-tabs-window>
        <InternalTrOutcomeDialog :dialog="outTrDialog" @close-dialog="closeDialog()"/>
        <InternalTrIncomeDialog :dialog="incomeTrDialog" @close-dialog="closeDialog()"/>
    </v-container>
</template>

<script>
import InternalTrTable from '@/components/internaltr/InternalTrTable.vue';
import InternalTrIncomeDialog from '@/components/internaltr/InternalTrIncomeDialog.vue';
import InternalTrOutcomeDialog from '@/components/internaltr/InternalTrOutcomeDialog.vue';


export default {
    components: {
        InternalTrTable,
        InternalTrIncomeDialog,
        InternalTrOutcomeDialog
    },
    data: () => ({
        tab: null,
        outTrDialog: false,
        incomeTrDialog: false,
    }),
    methods: {
        closeDialog() {
            this.refreshInternalTrTable();

            this.outTrDialog = false;
            this.incomeTrDialog = false;

            console.log(this.outTrDialog, this.incomeTrDialog);
        },
        async refreshInternalTrTable() {
            await this.$nextTick();
            if (this.tab === 1 && this.$refs.activeInternalTrTableComponent) {
                this.$refs.activeInternalTrTableComponent.getTransactions();
            } else if (this.tab === 2 && this.$refs.archiveInternalTrTableComponent) {
                this.$refs.archiveInternalTrTableComponent.getTransactions();
            } else {
                console.error('Дочерний компонент не найден');
            }
        },
    }
}
</script>