<template>
    <v-container fluid>
        <v-card class="ma-auto login-card">
            <v-card-title class="custom-surface text-center">
                Вход
            </v-card-title>
            <v-card-subtitle class="custom-surface text-center">Для регистрации, обратитесь в поддержку <a href="https://t.me/">@SuppotBot</a></v-card-subtitle>
                <v-card-text>
                    <v-text-field
                        label="Email"
                        placeholder="johndoe@gmail.com"
                        type="email"
                        v-model="email"
                    ></v-text-field>
                    <v-text-field
                        v-model="password"
                        :type="showPassword ? 'text' : 'password'"
                        label="Пароль"
                        :append-inner-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                        @click:append-inner="togglePasswordVisibility"
                    />
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mt-2" block color="primary" @click="submitForm">Войти</v-btn>
                </v-card-actions>
        </v-card>
    </v-container>
</template>

<script>
import apiClient from '@/plugins/axios'

export default {
    data: () => ({
        email: null,
        password: null,
        showPassword: false,
    }),
    mounted() {
        
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword
        },
        submitForm() {
            apiClient.post('user/login/', {
                email: this.email,
                password: this.password
            }).then(response => {
                console.log(response.data);
                this.$router.push('/');
            })
            .catch(error => {
                console.error(error);
            });
        },
    },
}
  

</script>


<style scoped>
.login-card {
    width: 40%;
}
</style>